
/* Box Model */

* {
  box-sizing: border-box;
}

/* Appearance */

* {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

/* Margins */

blockquote,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
figure,
ul,
li,
p,
pre {
  margin: 0;
  padding: 0;
}

/* Document */

html, body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  -webkit-font-smoothing: antialiased;
  overscroll-behavior: none;
  font-size: 16px;
  line-height: 1;
  margin: 0;
  padding: 0;
  height: 100%;
}

p {
  line-height: 1.5;
}

a {
  color: inherit;
}

/* Autocomplete */

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid var(--border);
  -webkit-text-fill-color: var(--foreground);
  transition: background-color 5000s ease-in-out 0s;
}

:root {
  --ease: ease;
  --ease-smooth: cubic-bezier(0.4, 0, 0.2, 1);
  --ease-inout: cubic-bezier(0.86, 0, 0.07, 1);
  --ease-snap: cubic-bezier(1, 0, 0, 1);
}

.Alert {
  --height: 3.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--background);
  box-shadow: var(--shadow-3);
  width: 100%;
  min-width: 350px;
  height: var(--height);
  border-radius: 4px;
  padding-left: 1.5rem;
  padding-right: 1rem;
  font-size: 14px;
  font-weight: 500;
  border-left: 2px solid var(--color, transparent);
}

.Alert.info {
  --color: var(--success-9);
}

.Alert.warning {
  --color: var(--warning-9);
}

.Alert.error {
  --color: var(--danger-9);
}

#root {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  color: var(--foreground);
}

/* TODO: no :active when disabled */

.Button {
  --height: 32px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  font-size: 12px;
  font-weight: 500;
  border: none;
  height: var(--height);
  padding: 0 1rem;
  outline: none;
  user-select: none;
  cursor: pointer;
  text-decoration: none;
  flex: 0 0 auto;
}

/* Disabled */

.Button.disabled {
  cursor: not-allowed;
}

/* Icon */

.Button.has-icon {
  padding: 0;
  width: var(--height);
}

.Button.has-icon > .icon {
  width: 14px;
  height: 14px;
}

.Button.has-icon-before > .icon {
  width: 12px;
  height: 12px;
  margin-right: 8px;
}

.Button.has-icon-after > .icon {
  width: 12px;
  height: 12px;
  margin-left: 8px;
}

/* Pending */

.Button.pending {
  overflow: hidden;
  color: rgba(0,0,0,0) !important;
  position: relative;
}

.Button.pending::before {
  --color: var(--neutral-6);
  --size: 0.75rem;
  --thickness: 1px;
  content: '';
  width: var(--size);
  height: var(--size);
  border-top-color: var(--color);
  border-left-color: var(--color);
  border-width: var(--thickness);
  animation: button-spinner 400ms linear infinite;
  border-bottom-color: transparent;
  border-right-color: transparent;
  border-style: solid;
  border-radius: 50%;  
  box-sizing: border-box;
  display: inline-block;
  will-change: transform;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@keyframes button-spinner {
  from {
    transform: translate(-50%, -50%) rotate(0deg)
  }
  
  to {
    transform: translate(-50%, -50%) rotate(360deg)
  }
}

/* Size Small */

.Button.small {
  --height: 24px;
}

.Button.small.pending::before {
  --size: 10px;
}

.Button.small > .icon {
  width: 12px;
  height: 12px;
}

.Checkbox {
  --background: var(--neutral-3);
  --foreground: var(--primary-6);
  display: block;
  line-height: 1.2rem;
  margin: .2rem 0;
  min-height: 1.4rem;
  padding: .1rem .4rem .1rem 1.2rem;
  position: relative;
  user-select: none;
  cursor: pointer;
}

.Checkbox > .icon {
  border-radius: 2px;
  height: .8rem;
  left: 0;
  top: .3rem;
  width: .8rem;
}

.Checkbox > .icon {
  border: .05rem solid var(--background);
  display: inline-block;
  position: absolute;
  transition: background .2s,border .2s, box-shadow .2s,color .2s;
}

.Checkbox > input:focus + .icon {
  box-shadow: 0 0 0 0.1rem rgba(87,85,217,.2);
}

/* Checked */

.Checkbox > input:checked + .icon {
  background: var(--foreground);
  border-color: var(--foreground);
}

.Checkbox > input:checked + .icon::before {
  background-clip: padding-box;
  border: 1.5px solid #fff;
  border-left-width: 0;
  border-top-width: 0;
  content: "";
  height: 6px;
  left: 50%;
  margin-left: -2px;
  margin-top: -6px;
  position: absolute;
  top: 55%;
  transform: rotate(45deg);
  width: 4px;
}

/* Disabled */

.Checkbox > input:disabled {
  cursor: not-allowed;
}

.Checkbox > input:disabled + .icon {
  background-color: var(--input-disabled-background);
  border-color: var(--neutral-2);
}

.CornerDialog {
  --width: 400px;
  --offset: 3rem;
  width: var(--width);
  box-shadow: var(--shadow-4);
  padding: 2rem;
  border-radius: 4px;
  background: var(--background);
  position: fixed;
  bottom: var(--offset);
  right: var(--offset);
}

.CornerDialog .top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.CornerDialog .actions {
  display: grid;
  grid-auto-flow: column;
  gap: 0.5rem;
  justify-content: flex-end;
  margin-top: 1rem;
}

.CornerDialog .title {
  font-size: 1.15rem;
  letter-spacing: -0.016em;
  font-weight: 600;
  cursor: default;
}

.CornerDialog p {
  margin-top: 0.5rem;
  letter-spacing: 0.004em;
  font-weight: 400;
  font-size: 0.9rem;
  line-height: 1.6;
  cursor: default;
  color: var(--text-muted);
}

.Dialog {
  background: var(--background);
  margin-top: 12vmin;
  margin-bottom: 12vmin;
  max-height: calc(100% - 12vmin * 2);
  max-width: calc(100% - 16px * 2);
  width: 540px;
  box-shadow: var(--shadow-3);
  border-radius: 3px;
  z-index: 100;
}

.DialogActions {
  display: grid;
  grid-auto-flow: column;
  gap: 0.5rem;
  justify-content: flex-end;
  border-top: 1px solid var(--border);
  padding: 1rem;
}

.Empty {
  flex: 1 1 auto;
}

.Empty .content {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 500px;
  margin: 12vmin auto;
  padding: 5rem;
}

.Empty .actions {
  margin-top: 2rem;
}

/* Row */

.FormRow {
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  grid-gap: 1rem;
  margin-bottom: 1.5rem;
}

/* Actions */

.FormActions {
  display: grid;
  grid-auto-flow: column;
  gap: 0.5rem;
  justify-content: flex-end;
}

/* Inputs */

.TagInput,
.Input,
.Textarea,
.Select {
  border-radius: 2px;
  font-size: 12px;
  font-weight: 400;
  color: var(--foreground);
  background-color: var(--background);
  box-shadow: var(--input-border) 0px 0px 0px 1px;
  width: 100%;
  border: none;
  outline: none;
  overflow: hidden;
}

.TagInput,
.Input,
.Select {
  --height: 2rem;
  height: var(--height);
  line-height: var(--height);
  padding: 0 10px;
}

.Textarea {
  padding: 10px;
}

.TagInput.disabled,
.Input[disabled],
.Textarea[disabled],
.Select[disabled] {
  background-color: var(--input-disabled-background);
  color: var(--input-disabled-foreground);
  cursor: not-allowed;
}

.Input:focus,
.Textarea:focus,
.Select:focus {
  box-shadow: var(--input-focus-shadow);
}

.Input::placeholder,
.Textarea::placeholder,
.Select::placeholder {
  color: var(--text-placeholder)
}

/* Select */

.Select {
  --icon: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI2IiBoZWlnaHQ9IjYiIHZpZXdCb3g9IjAgMCAyOTIuMzYyIDI5Mi4zNjIiPjxwYXRoIGQ9Ik0yODYuOTM1IDY5LjM3N2MtMy42MTQtMy42MTctNy44OTgtNS40MjQtMTIuODQ4LTUuNDI0SDE4LjI3NGMtNC45NTIgMC05LjIzMyAxLjgwNy0xMi44NSA1LjQyNEMxLjgwNyA3Mi45OTggMCA3Ny4yNzkgMCA4Mi4yMjhjMCA0Ljk0OCAxLjgwNyA5LjIyOSA1LjQyNCAxMi44NDdsMTI3LjkwNyAxMjcuOTA3YzMuNjIxIDMuNjE3IDcuOTAyIDUuNDI4IDEyLjg1IDUuNDI4czkuMjMzLTEuODExIDEyLjg0Ny01LjQyOEwyODYuOTM1IDk1LjA3NGMzLjYxMy0zLjYxNyA1LjQyNy03Ljg5OCA1LjQyNy0xMi44NDcgMC00Ljk0OC0xLjgxNC05LjIyOS01LjQyNy0xMi44NXoiLz48L3N2Zz4K");
  background-image: var(--icon);
  background-repeat: no-repeat;
  background-position: right 15px top 50%;
}

/* Number input reset */

/* Chrome, Safari, Edge, Opera */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */

input[type=number] {
  -moz-appearance: textfield;
}

/* Search */

input[type="search"]::-webkit-search-cancel-button {
  --size: 11px;
  --background: var(--search-cancel-button-bg, #7F7F7F);
  --icon: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI3IiBoZWlnaHQ9IjciIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjZmZmIiBzdHJva2Utd2lkdGg9IjMiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgY2xhc3M9ImZlYXRoZXIgZmVhdGhlci14Ij48cGF0aCBkPSJNMTggNkw2IDE4TTYgNmwxMiAxMiIvPjwvc3ZnPgo=");
  -webkit-appearance: none;
  height: var(--size);
  width: var(--size);
  border-radius: 50%;
  background: var(--background) var(--icon) 50% 50% no-repeat;
}

input[type="search"]::-webkit-search-cancel-button:hover {
  opacity: 0.9;
}

/* Labels */

.FormField > label {
  display: block;
  margin-bottom: 0.25rem;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.25rem;
}

.FormField.required > label::after {
  content: ' *';
}

/* Hints */

.FormField > .hint {
  font-size: 13px;
  color: var(--text-muted);
  margin-top: 0.5rem;
  margin-top: 0.25rem;
  line-height: 1.4;
}

.IconInput {
  position: relative;
  padding-left: 0;
}

.IconInput > .icon {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  height: 100%;
  width: 2rem;
  pointer-events: none;
}

.IconInput > .icon > svg {
  color: var(--neutral-8);
  width: 12px;
}

.IconInput > input {
  padding-left: 2rem;
}

.Menu {
  --margin: 1.5rem;
  margin-left: var(--margin);
  margin-right: var(--margin);
}

.Menu.collapsed {
  --margin: 0.5rem;
}

/* Popover */

.Menu.full {
  --margin: 0;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.Menu.full .MenuItem {
  --padding: 1.5rem;
}

/* Divider */

.Menu > .divider {
  border-top: 1px solid var(--border);
}

/* Menu Group */

.MenuGroup {
  padding: 0.5rem 0;
}

.MenuGroup > .title {
  margin: 0.5rem 1rem;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 500;
  user-select: none;
  color: var(--neutral-6);
}

/* Options */

.MenuGroup.options .MenuItem {
  padding-left: 2.5rem;
}

.MenuGroup.options .MenuItem.selected {
  color: var(--primary-8);
}

.MenuGroup.options .MenuItem.selected::before {
  line-height: 0;
  display: block;
  content: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNCIgaGVpZ2h0PSIxNCIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIiBzdHJva2U9IiMzRTYwRDgiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIj48cGF0aCBkPSJNMjAgNkw5IDE3bC01LTUiLz48L3N2Zz4K");
  margin-left: -14px;
  transform: translateX(-50%);
}

/* Menu Item */

.MenuItem {
  --height: 2.5rem;
  --padding: 1rem;
  display: flex;
  align-items: center;
  text-decoration: none;
  height: var(--height);
  line-height: var(--height);
  font-size: 14px;
  font-weight: 400;
  color: var(--neutral-9);
  border-radius: 2px;
  padding: 0 var(--padding);
  cursor: pointer;
  user-select: none;
}

.MenuItem > .icon {
  width: 14px;
  height: 14px;
}

.MenuItem:hover {
  background: var(--neutral-0); /* TODO: lighter color? */
  color: var(--foreground);
}

.MenuItem:active {
  background: var(--neutral-1);
}

.MenuItem:hover > .icon {
  stroke: #000;
}

.MenuItem > .icon {
  stroke: #888;
  margin-right: 0.75rem;
}

/* Selected */

.MenuItem.selected {
  font-weight: 600;
  color: var(--foreground);
}

.MenuItem.selected > .icon {
  stroke: var(--foreground);
}

/* Collapsed */

.Menu.collapsed > .MenuItem {
  justify-content: center;
}

.Menu.collapsed > .MenuItem > .icon {
  margin-right: 0;
}

.Menu.collapsed > .MenuItem > .text {
  display: none;
}

/* Small */

.Menu.small .MenuItem {
  --padding: 1rem;
  --height: 2rem;
  font-size: 12px;
}

.Overlay {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  z-index: 50;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: default;
  will-change: opacity;
}

.Overlay::before {
  background-color: rgba(28, 30, 38, 0.4);
  left: 0;
  top: 0;
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  content: " ";
  opacity: 0;
  animation: overlay 400ms ease-out both;
  z-index: 50;
}

@keyframes overlay {
  from {
    opacity: 0;
  }
  
  to {
    opacity: 1;
  }
}

.Popover {
  --ease: cubic-bezier(0.175, 0.885, 0.320, 1);
  display: inline-block;
  box-shadow: var(--shadow-3);
  min-width: 200px;
  border-radius: 4px;
  animation: popover-show 300ms var(--ease) forwards;
  opacity: 0;
  background: var(--background);
  z-index: 100;
}

@keyframes popover-show {
  from {
    opacity: 0;
    transform: translateY(-1.5rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* TODO: remove animations? */

/*
  deceleration: `cubic-bezier(0.0, 0.0, 0.2, 1)`,
  acceleration: `cubic-bezier(0.4, 0.0, 1, 1)`,
  spring: `cubic-bezier(0.175, 0.885, 0.320, 1.175)`
*/

.ProgressBar {
  --height: 0.25rem;
  --radius: 4px;
  --background: var(--neutral-2);
  --foreground: var(--primary-6);
  width: 100%;
  min-width: 100px;
  height: var(--height);
  background: var(--background);
  border-radius: var(--radius);
  transition: width 200ms ease-out;
  will-change: width;
}

.ProgressBar > .bar {
  background: var(--foreground);
  height: 100%;
  border-radius: var(--radius);
}

.Radio {
  --background: var(--neutral-3);
  --foreground: var(--primary-6);
  display: block;
  line-height: 1.2rem;
  margin: .2rem 0;
  min-height: 1.4rem;
  padding: .1rem .4rem .1rem 1.2rem;
  position: relative;
  user-select: none;
  cursor: pointer;
}

.Radio > .icon {
  border-radius: 50%;
  background: var(--background);
  height: .8rem;
  left: 0;
  top: .3rem;
  width: .8rem;
}

.Radio > .icon {
  border: .05rem solid var(--background);
  display: inline-block;
  position: absolute;
  transition: background .2s,border .2s,box-shadow .2s,color .2s;
}

.Radio > input:focus + .icon {
  box-shadow: 0 0 0 0.1rem rgba(87,85,217,.2);
}

.Radio > input:checked + .icon {
  background: var(--foreground);
  border-color: var(--foreground);
}

.Resizer {
  --color: HSL(206, 97%, 55%);
  --size: 16px;
  width: var(--size);
  background: transparent;
  position: absolute;
  z-index: 5;
  border: none;
  transition: border 200ms ease-out;
  user-select: none;
  height: 100%;
  top: 0;
  cursor: ew-resize;
}

.Resizer.toggle.left {
  cursor: w-resize;
}

.Resizer.toggle.right {
  cursor: e-resize;
}

.Resizer.expanded.left {
  cursor: e-resize;
}

.Resizer.expanded.right {
  cursor: w-resize;
}

.Resizer.right {
  right: 0;
  border-right: 3px solid transparent;
}

.Resizer.left {
  left: 0;
  border-left: 3px solid transparent;
}

.Resizer:hover {
  border-color: var(--color);
}

.Resizer:not(.toggle):active {
  cursor: col-resize;
}

.Sidebar {
  transition: width 200ms var(--ease-inout);
  will-change: width;
  overflow: hidden;
}

.Sidebar .Menu { 
  --margin: 0;
  margin: 0.75rem 0.5rem;
}

.Sidebar .Nav {
  align-self: stretch;
}

.Sidebar:not(.expanded) .MenuItem {
  display: flex;
  justify-content: center;
  padding: 0;
}

.Sidebar .MenuItem .text {
  display: none;
  position: relative;
  left: 0.5rem;
  opacity: 0;
}

.Sidebar .MenuItem .icon {
  margin-right: 0;
}

.Sidebar.expanded .MenuItem .text {
  display: block;
  animation: 350ms nav-text-show 150ms ease-out forwards;
}

@keyframes nav-text-show {
  from {
    opacity: 0;
    transform: translateX(-0.5rem);
  }

  to {
    opacity: 1;
  }
}

.SideSheet {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 75;
  background: var(--background);
  height: 100vh;
  width: 620px;
  box-shadow: var(--shadow-4);
  display: flex;
  flex-direction: column;
  transition: width 250ms var(--ease-smooth);
}

.SideSheet.expanded {
  width: 75vw;
}

.SideSheet.show {
  animation: 200ms sidesheet-show ease-out forwards;
}

.SideSheet.hide {
  animation: 200ms sidesheet-hide ease-out forwards;
}

.SideSheet > .close {
  --size: 1.75rem;
  width: var(--size);
  height: var(--size);
  position: absolute;
  top: 1rem;
  left: calc(-1 * var(--size) + -1rem);
  color: var(--foreground);
  background: var(--background);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.SideSheet > .close:active {
  margin-top: 1px;
}

.SideSheet > .close > .icon {
  width: 14px;
  height: 14px;
}

/* Animations */

@keyframes sidesheet-show {
  from {
    opacity: 0;
    transform: translateX(50%);
  }
  
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes sidesheet-hide {
  from {
    opacity: 1;
    transform: translateX(0);
  }
  
  to {
    opacity: 0;
    transform: translateX(50%);
  }
}

.Spinner {
  --color: var(--neutral-7);
  --size: 1rem;
  --thickness: 1px;
  width: var(--size);
  height: var(--size);
  border-top-color: var(--color);
  border-left-color: var(--color);
  border-width: var(--thickness);
  animation: spinner 400ms linear infinite;
  border-bottom-color: transparent;
  border-right-color: transparent;
  border-style: solid;
  border-radius: 50%;  
  box-sizing: border-box;
  display: inline-block;
  vertical-align: middle;
  will-change: transform;
}

@keyframes spinner {
  from {
    transform: rotate(0deg)
  }
  
  to {
    transform: rotate(360deg)
  }
}

/* Tabs */

.Tabs {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  min-height: 0;
}

/* Labels */

.Tabs > .labels {
  display: flex;
  padding: 0.5rem; 
}

.Tabs > .labels > .label {
  margin-right: 0.25rem;
}

/* Content */

.Tabs > .content {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 0;
}

/* TagInput */

.TagInput {
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;
  height: auto;
}

.TagInput.focus {
  box-shadow: var(--input-focus-shadow);
}

.TagInput > .Input {
  box-shadow: none;
  padding-left: 0.25rem;
  background: transparent;
  width: auto;
}

/* Tag */

.Tag {
  display: inline-flex;
  height: 1rem;
  align-items: center;
  padding: 0.25rem 0.5rem;
  margin: 0.5rem 2px;
  font-size: 11px;
  border-radius: 2px;
  background: var(--neutral-2);
  user-select: none;
}

.TagInput:not(.disabled) .Tag:hover {
  background: var(--neutral-1);
  color: var(--neutral-7);
  cursor: pointer;
}

.Tag > .icon {
  margin-left: 0.25rem;
  width: 11px;
}

.TeamSelect {
  --height: 4rem;
  --padding: 1.5rem;
  --icon: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNCIgaGVpZ2h0PSIxNCIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIiBzdHJva2U9IkhTTCgwLCAwJSwgNTAlKSIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiPjxwYXRoIGQ9Ik02IDlsNiA2IDYtNiIvPjwvc3ZnPgo=");
  width: 100%;
  height: var(--height);
  line-height: var(--height);
  border: none;
  border-bottom: 1px solid rgb(233, 236, 239);
  border-radius: 0;
  padding: 0 var(--padding);
  font-weight: 600;
  font-size: 1rem;
  outline: none;
  background: var(--background) var(--icon) no-repeat;
  background-position: right var(--padding) top 50%;
  cursor: pointer;
  user-select: none;
}

/* Responsive */

@media screen and (max-width: 900px) {
  .TeamSelect {
    text-indent: -99rem;
  }
}

/* TODO: gray for this... others are too dark? */

/* TODO: more generic? */

/* TODO: color variables */

.Toasts {
  --offset: 3rem;
  position: fixed;
  bottom: var(--offset);
  right: var(--offset);
  width: 350px;
  display: grid;
  gap: 0.5rem;
}

.Toggle {
  --ease: cubic-bezier(0.175, 0.885, 0.320, 1.275);
  --background: var(--neutral-3);
  --foreground: var(--primary-6);
  display: block;
  line-height: 1.2rem;
  margin: .2rem 0;
  min-height: 1.4rem;
  padding: .1rem .4rem .1rem 1.2rem;
  position: relative;
  padding-left: 2.5rem;
  user-select: none;
  cursor: pointer;
}

.Toggle > input {
  clip: rect(0,0,0,0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  position: absolute;
  width: 1px;
}

.Toggle > input:focus + .icon {
  box-shadow: 0 0 0 0.1rem rgba(87,85,217,.2);
}

.Toggle > input:checked + .icon {
  background: var(--foreground);
  border-color: var(--foreground);
}

.Toggle > input:checked + .icon::before {
  left: 12px;
}

.Toggle > .icon {
  background: var(--background);
  background-clip: padding-box;
  border-radius: .45rem;
  height: .9rem;
  left: 0;
  top: .2rem;
  width: 1.7rem;
  border: .05rem solid var(--background);
  display: inline-block;
  position: absolute;
  transition: all 200ms ease;
}

.Toggle > .icon::before {
  background: #fff;
  border-radius: 50%;
  content: "";
  display: block;
  height: .8rem;
  left: 0;
  position: absolute;
  top: 0;
  transition: all 200ms var(--ease);
  width: .8rem;
}

.Toggle:active > input:checked + .icon::before {
  padding-left: 0.15rem;
  margin-left: -0.15rem;
}

.Toggle:active > input:not(:checked) + .icon::before {
  padding-right: 0.15rem;
}

.Topbar {
  height: 4rem;
  box-shadow: rgb(240, 240, 240) 0px 5px 5px -5px;
  border-bottom: 1px solid #e9ecef;
  background: var(--background);
  flex: 0 0 auto;
}

.Topbar > .spaced {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  height: 100%;
}

/* Display */

.block { display: block }

.inline-block { display: inline-block }

.inline { display: inline }

.flex { display: flex }

.grid { display: grid }

.inline-flex { display: inline-flex }

.table { display: table }

.table-row { display: table-row }

.table-cell { display: table-cell }

.hidden { display: none }

/* Gap */

.gap-0 { gap: 0rem }

.gap-1 { gap: 0.25rem }

.gap-2 { gap: 0.5rem }

.gap-3 { gap: 0.75rem }

.gap-4 { gap: 1rem }

.gap-5 { gap: 1.25rem }

.gap-6 { gap: 1.5rem }

.gap-7 { gap: 1.75rem }

.gap-8 { gap: 2rem }

.gap-9 { gap: 2.25rem }

.gap-10 { gap: 2.5rem }

.gap-11 { gap: 2.75rem }

.gap-12 { gap: 3rem }

.gap-13 { gap: 3.25rem }

.gap-14 { gap: 3.5rem }

.gap-15 { gap: 3.75rem }

.gap-16 { gap: 4rem }

.gap-17 { gap: 4.25rem }

.gap-18 { gap: 4.5rem }

.gap-19 { gap: 4.75rem }

.gap-20 { gap: 5rem }

.gap-21 { gap: 5.25rem }

.gap-22 { gap: 5.5rem }

.gap-23 { gap: 5.75rem }

.gap-24 { gap: 6rem }

.gap-25 { gap: 6.25rem }

.gap-26 { gap: 6.5rem }

.gap-27 { gap: 6.75rem }

.gap-28 { gap: 7rem }

.gap-29 { gap: 7.25rem }

.gap-30 { gap: 7.5rem }

.gap-31 { gap: 7.75rem }

/* Object Fit */

.object-contain { object-fit: contain }

.object-cover { object-fit: cover }

.object-fill { object-fit: fill }

.object-none { object-fit: none }

.object-scale-down { object-fit: scale-down }

/* Overflow */

.overflow-auto { overflow: auto }

.overflow-hidden { overflow: hidden }

.overflow-visible { overflow: visible }

.overflow-scroll { overflow: scroll }

.overflow-x-auto { overflow-x: auto }

.overflow-y-auto { overflow-y: auto }

.overflow-x-hidden { overflow-x: hidden }

.overflow-y-hidden { overflow-y: hidden }

.overflow-x-visible { overflow-x: visible }

.overflow-y-visible { overflow-y: visible }

.overflow-x-scroll { overflow-x: scroll }

.overflow-y-scroll { overflow-y: scroll }

.scrolling-touch { -webkit-overflow-scrolling: touch }

.scrolling-auto { -webkit-overflow-scrolling: auto }

/* Z-Index */

.z-0 { z-index: 0 }

.z-10 { z-index: 10 }

.z-20 { z-index: 20 }

.z-30 { z-index: 30 }

.z-40 { z-index: 40 }

.z-50 { z-index: 50 }

.z-60 { z-index: 60 }

.z-70 { z-index: 70 }

.z-80 { z-index: 80 }

.z-90 { z-index: 90 }

.z-100 { z-index: 100 }

.z-auto { z-index: auto }

/* Visibility */

.visible { visibility: visible }

.invisible { visibility: hidden }

/* Position */

.static { position: static }

.fixed { position: fixed }

.absolute { position: absolute }

.relative { position: relative }

.sticky { position: sticky }

/* Width */

.w-0 { width: 0rem }

.w-1 { width: 1rem }

.w-2 { width: 1.5rem }

.w-3 { width: 2rem }

.w-4 { width: 2.5rem }

.w-5 { width: 3rem }

.w-6 { width: 3.5rem }

.w-7 { width: 4rem }

.w-8 { width: 4.5rem }

.w-9 { width: 5rem }

/* Height */

.h-0 { height: 0rem }

.h-1 { height: 1rem }

.h-2 { height: 1.5rem }

.h-3 { height: 2rem }

.h-4 { height: 2.5rem }

.h-5 { height: 3rem }

.h-6 { height: 3.5rem }

.h-7 { height: 4rem }

.h-8 { height: 4.5rem }

.h-9 { height: 5rem }

/* Margin */

.m-0 { margin: 0rem }

.m-1 { margin: 0.25rem }

.m-2 { margin: 0.5rem }

.m-3 { margin: 0.75rem }

.m-4 { margin: 1rem }

.m-5 { margin: 1.25rem }

.m-6 { margin: 1.5rem }

.m-7 { margin: 1.75rem }

.m-8 { margin: 2rem }

.m-9 { margin: 2.25rem }

.m-10 { margin: 2.5rem }

.m-11 { margin: 2.75rem }

.m-12 { margin: 3rem }

.m-13 { margin: 3.25rem }

.m-14 { margin: 3.5rem }

.m-15 { margin: 3.75rem }

.m-16 { margin: 4rem }

.m-17 { margin: 4.25rem }

.m-18 { margin: 4.5rem }

.m-19 { margin: 4.75rem }

.m-20 { margin: 5rem }

.m-21 { margin: 5.25rem }

.m-22 { margin: 5.5rem }

.m-23 { margin: 5.75rem }

.m-24 { margin: 6rem }

.m-25 { margin: 6.25rem }

.m-26 { margin: 6.5rem }

.m-27 { margin: 6.75rem }

.m-28 { margin: 7rem }

.m-29 { margin: 7.25rem }

.m-30 { margin: 7.5rem }

.m-31 { margin: 7.75rem }

/* Margin X */

.mx-0 { margin-left: 0rem; margin-right: 0rem }

.mx-1 { margin-left: 0.25rem; margin-right: 0.25rem }

.mx-2 { margin-left: 0.5rem; margin-right: 0.5rem }

.mx-3 { margin-left: 0.75rem; margin-right: 0.75rem }

.mx-4 { margin-left: 1rem; margin-right: 1rem }

.mx-5 { margin-left: 1.25rem; margin-right: 1.25rem }

.mx-6 { margin-left: 1.5rem; margin-right: 1.5rem }

.mx-7 { margin-left: 1.75rem; margin-right: 1.75rem }

.mx-8 { margin-left: 2rem; margin-right: 2rem }

.mx-9 { margin-left: 2.25rem; margin-right: 2.25rem }

.mx-10 { margin-left: 2.5rem; margin-right: 2.5rem }

.mx-11 { margin-left: 2.75rem; margin-right: 2.75rem }

.mx-12 { margin-left: 3rem; margin-right: 3rem }

.mx-13 { margin-left: 3.25rem; margin-right: 3.25rem }

.mx-14 { margin-left: 3.5rem; margin-right: 3.5rem }

.mx-15 { margin-left: 3.75rem; margin-right: 3.75rem }

.mx-16 { margin-left: 4rem; margin-right: 4rem }

.mx-17 { margin-left: 4.25rem; margin-right: 4.25rem }

.mx-18 { margin-left: 4.5rem; margin-right: 4.5rem }

.mx-19 { margin-left: 4.75rem; margin-right: 4.75rem }

.mx-20 { margin-left: 5rem; margin-right: 5rem }

.mx-21 { margin-left: 5.25rem; margin-right: 5.25rem }

.mx-22 { margin-left: 5.5rem; margin-right: 5.5rem }

.mx-23 { margin-left: 5.75rem; margin-right: 5.75rem }

.mx-24 { margin-left: 6rem; margin-right: 6rem }

.mx-25 { margin-left: 6.25rem; margin-right: 6.25rem }

.mx-26 { margin-left: 6.5rem; margin-right: 6.5rem }

.mx-27 { margin-left: 6.75rem; margin-right: 6.75rem }

.mx-28 { margin-left: 7rem; margin-right: 7rem }

.mx-29 { margin-left: 7.25rem; margin-right: 7.25rem }

.mx-30 { margin-left: 7.5rem; margin-right: 7.5rem }

.mx-31 { margin-left: 7.75rem; margin-right: 7.75rem }

/* Margin Y */

.my-0 { margin-top: 0rem; margin-bottom: 0rem }

.my-1 { margin-top: 0.25rem; margin-bottom: 0.25rem }

.my-2 { margin-top: 0.5rem; margin-bottom: 0.5rem }

.my-3 { margin-top: 0.75rem; margin-bottom: 0.75rem }

.my-4 { margin-top: 1rem; margin-bottom: 1rem }

.my-5 { margin-top: 1.25rem; margin-bottom: 1.25rem }

.my-6 { margin-top: 1.5rem; margin-bottom: 1.5rem }

.my-7 { margin-top: 1.75rem; margin-bottom: 1.75rem }

.my-8 { margin-top: 2rem; margin-bottom: 2rem }

.my-9 { margin-top: 2.25rem; margin-bottom: 2.25rem }

.my-10 { margin-top: 2.5rem; margin-bottom: 2.5rem }

.my-11 { margin-top: 2.75rem; margin-bottom: 2.75rem }

.my-12 { margin-top: 3rem; margin-bottom: 3rem }

.my-13 { margin-top: 3.25rem; margin-bottom: 3.25rem }

.my-14 { margin-top: 3.5rem; margin-bottom: 3.5rem }

.my-15 { margin-top: 3.75rem; margin-bottom: 3.75rem }

.my-16 { margin-top: 4rem; margin-bottom: 4rem }

.my-17 { margin-top: 4.25rem; margin-bottom: 4.25rem }

.my-18 { margin-top: 4.5rem; margin-bottom: 4.5rem }

.my-19 { margin-top: 4.75rem; margin-bottom: 4.75rem }

.my-20 { margin-top: 5rem; margin-bottom: 5rem }

.my-21 { margin-top: 5.25rem; margin-bottom: 5.25rem }

.my-22 { margin-top: 5.5rem; margin-bottom: 5.5rem }

.my-23 { margin-top: 5.75rem; margin-bottom: 5.75rem }

.my-24 { margin-top: 6rem; margin-bottom: 6rem }

.my-25 { margin-top: 6.25rem; margin-bottom: 6.25rem }

.my-26 { margin-top: 6.5rem; margin-bottom: 6.5rem }

.my-27 { margin-top: 6.75rem; margin-bottom: 6.75rem }

.my-28 { margin-top: 7rem; margin-bottom: 7rem }

.my-29 { margin-top: 7.25rem; margin-bottom: 7.25rem }

.my-30 { margin-top: 7.5rem; margin-bottom: 7.5rem }

.my-31 { margin-top: 7.75rem; margin-bottom: 7.75rem }

/* Margin Top */

.mt-0 { margin-top: 0rem }

.mt-1 { margin-top: 0.25rem }

.mt-2 { margin-top: 0.5rem }

.mt-3 { margin-top: 0.75rem }

.mt-4 { margin-top: 1rem }

.mt-5 { margin-top: 1.25rem }

.mt-6 { margin-top: 1.5rem }

.mt-7 { margin-top: 1.75rem }

.mt-8 { margin-top: 2rem }

.mt-9 { margin-top: 2.25rem }

.mt-10 { margin-top: 2.5rem }

.mt-11 { margin-top: 2.75rem }

.mt-12 { margin-top: 3rem }

.mt-13 { margin-top: 3.25rem }

.mt-14 { margin-top: 3.5rem }

.mt-15 { margin-top: 3.75rem }

.mt-16 { margin-top: 4rem }

.mt-17 { margin-top: 4.25rem }

.mt-18 { margin-top: 4.5rem }

.mt-19 { margin-top: 4.75rem }

.mt-20 { margin-top: 5rem }

.mt-21 { margin-top: 5.25rem }

.mt-22 { margin-top: 5.5rem }

.mt-23 { margin-top: 5.75rem }

.mt-24 { margin-top: 6rem }

.mt-25 { margin-top: 6.25rem }

.mt-26 { margin-top: 6.5rem }

.mt-27 { margin-top: 6.75rem }

.mt-28 { margin-top: 7rem }

.mt-29 { margin-top: 7.25rem }

.mt-30 { margin-top: 7.5rem }

.mt-31 { margin-top: 7.75rem }

/* Margin Right */

.mr-0 { margin-right: 0rem }

.mr-1 { margin-right: 0.25rem }

.mr-2 { margin-right: 0.5rem }

.mr-3 { margin-right: 0.75rem }

.mr-4 { margin-right: 1rem }

.mr-5 { margin-right: 1.25rem }

.mr-6 { margin-right: 1.5rem }

.mr-7 { margin-right: 1.75rem }

.mr-8 { margin-right: 2rem }

.mr-9 { margin-right: 2.25rem }

.mr-10 { margin-right: 2.5rem }

.mr-11 { margin-right: 2.75rem }

.mr-12 { margin-right: 3rem }

.mr-13 { margin-right: 3.25rem }

.mr-14 { margin-right: 3.5rem }

.mr-15 { margin-right: 3.75rem }

.mr-16 { margin-right: 4rem }

.mr-17 { margin-right: 4.25rem }

.mr-18 { margin-right: 4.5rem }

.mr-19 { margin-right: 4.75rem }

.mr-20 { margin-right: 5rem }

.mr-21 { margin-right: 5.25rem }

.mr-22 { margin-right: 5.5rem }

.mr-23 { margin-right: 5.75rem }

.mr-24 { margin-right: 6rem }

.mr-25 { margin-right: 6.25rem }

.mr-26 { margin-right: 6.5rem }

.mr-27 { margin-right: 6.75rem }

.mr-28 { margin-right: 7rem }

.mr-29 { margin-right: 7.25rem }

.mr-30 { margin-right: 7.5rem }

.mr-31 { margin-right: 7.75rem }

/* Margin Bottom */

.mb-0 { margin-bottom: 0rem }

.mb-1 { margin-bottom: 0.25rem }

.mb-2 { margin-bottom: 0.5rem }

.mb-3 { margin-bottom: 0.75rem }

.mb-4 { margin-bottom: 1rem }

.mb-5 { margin-bottom: 1.25rem }

.mb-6 { margin-bottom: 1.5rem }

.mb-7 { margin-bottom: 1.75rem }

.mb-8 { margin-bottom: 2rem }

.mb-9 { margin-bottom: 2.25rem }

.mb-10 { margin-bottom: 2.5rem }

.mb-11 { margin-bottom: 2.75rem }

.mb-12 { margin-bottom: 3rem }

.mb-13 { margin-bottom: 3.25rem }

.mb-14 { margin-bottom: 3.5rem }

.mb-15 { margin-bottom: 3.75rem }

.mb-16 { margin-bottom: 4rem }

.mb-17 { margin-bottom: 4.25rem }

.mb-18 { margin-bottom: 4.5rem }

.mb-19 { margin-bottom: 4.75rem }

.mb-20 { margin-bottom: 5rem }

.mb-21 { margin-bottom: 5.25rem }

.mb-22 { margin-bottom: 5.5rem }

.mb-23 { margin-bottom: 5.75rem }

.mb-24 { margin-bottom: 6rem }

.mb-25 { margin-bottom: 6.25rem }

.mb-26 { margin-bottom: 6.5rem }

.mb-27 { margin-bottom: 6.75rem }

.mb-28 { margin-bottom: 7rem }

.mb-29 { margin-bottom: 7.25rem }

.mb-30 { margin-bottom: 7.5rem }

.mb-31 { margin-bottom: 7.75rem }

/* Margin Left */

.ml-0 { margin-left: 0rem }

.ml-1 { margin-left: 0.25rem }

.ml-2 { margin-left: 0.5rem }

.ml-3 { margin-left: 0.75rem }

.ml-4 { margin-left: 1rem }

.ml-5 { margin-left: 1.25rem }

.ml-6 { margin-left: 1.5rem }

.ml-7 { margin-left: 1.75rem }

.ml-8 { margin-left: 2rem }

.ml-9 { margin-left: 2.25rem }

.ml-10 { margin-left: 2.5rem }

.ml-11 { margin-left: 2.75rem }

.ml-12 { margin-left: 3rem }

.ml-13 { margin-left: 3.25rem }

.ml-14 { margin-left: 3.5rem }

.ml-15 { margin-left: 3.75rem }

.ml-16 { margin-left: 4rem }

.ml-17 { margin-left: 4.25rem }

.ml-18 { margin-left: 4.5rem }

.ml-19 { margin-left: 4.75rem }

.ml-20 { margin-left: 5rem }

.ml-21 { margin-left: 5.25rem }

.ml-22 { margin-left: 5.5rem }

.ml-23 { margin-left: 5.75rem }

.ml-24 { margin-left: 6rem }

.ml-25 { margin-left: 6.25rem }

.ml-26 { margin-left: 6.5rem }

.ml-27 { margin-left: 6.75rem }

.ml-28 { margin-left: 7rem }

.ml-29 { margin-left: 7.25rem }

.ml-30 { margin-left: 7.5rem }

.ml-31 { margin-left: 7.75rem }

/* Padding */

.p-0 { padding: 0rem }

.p-1 { padding: 0.25rem }

.p-2 { padding: 0.5rem }

.p-3 { padding: 0.75rem }

.p-4 { padding: 1rem }

.p-5 { padding: 1.25rem }

.p-6 { padding: 1.5rem }

.p-7 { padding: 1.75rem }

.p-8 { padding: 2rem }

.p-9 { padding: 2.25rem }

.p-10 { padding: 2.5rem }

.p-11 { padding: 2.75rem }

.p-12 { padding: 3rem }

.p-13 { padding: 3.25rem }

.p-14 { padding: 3.5rem }

.p-15 { padding: 3.75rem }

.p-16 { padding: 4rem }

.p-17 { padding: 4.25rem }

.p-18 { padding: 4.5rem }

.p-19 { padding: 4.75rem }

.p-20 { padding: 5rem }

.p-21 { padding: 5.25rem }

.p-22 { padding: 5.5rem }

.p-23 { padding: 5.75rem }

.p-24 { padding: 6rem }

.p-25 { padding: 6.25rem }

.p-26 { padding: 6.5rem }

.p-27 { padding: 6.75rem }

.p-28 { padding: 7rem }

.p-29 { padding: 7.25rem }

.p-30 { padding: 7.5rem }

.p-31 { padding: 7.75rem }

/* Padding X */

.px-0 { padding-left: 0rem; padding-right: 0rem }

.px-1 { padding-left: 0.25rem; padding-right: 0.25rem }

.px-2 { padding-left: 0.5rem; padding-right: 0.5rem }

.px-3 { padding-left: 0.75rem; padding-right: 0.75rem }

.px-4 { padding-left: 1rem; padding-right: 1rem }

.px-5 { padding-left: 1.25rem; padding-right: 1.25rem }

.px-6 { padding-left: 1.5rem; padding-right: 1.5rem }

.px-7 { padding-left: 1.75rem; padding-right: 1.75rem }

.px-8 { padding-left: 2rem; padding-right: 2rem }

.px-9 { padding-left: 2.25rem; padding-right: 2.25rem }

.px-10 { padding-left: 2.5rem; padding-right: 2.5rem }

.px-11 { padding-left: 2.75rem; padding-right: 2.75rem }

.px-12 { padding-left: 3rem; padding-right: 3rem }

.px-13 { padding-left: 3.25rem; padding-right: 3.25rem }

.px-14 { padding-left: 3.5rem; padding-right: 3.5rem }

.px-15 { padding-left: 3.75rem; padding-right: 3.75rem }

.px-16 { padding-left: 4rem; padding-right: 4rem }

.px-17 { padding-left: 4.25rem; padding-right: 4.25rem }

.px-18 { padding-left: 4.5rem; padding-right: 4.5rem }

.px-19 { padding-left: 4.75rem; padding-right: 4.75rem }

.px-20 { padding-left: 5rem; padding-right: 5rem }

.px-21 { padding-left: 5.25rem; padding-right: 5.25rem }

.px-22 { padding-left: 5.5rem; padding-right: 5.5rem }

.px-23 { padding-left: 5.75rem; padding-right: 5.75rem }

.px-24 { padding-left: 6rem; padding-right: 6rem }

.px-25 { padding-left: 6.25rem; padding-right: 6.25rem }

.px-26 { padding-left: 6.5rem; padding-right: 6.5rem }

.px-27 { padding-left: 6.75rem; padding-right: 6.75rem }

.px-28 { padding-left: 7rem; padding-right: 7rem }

.px-29 { padding-left: 7.25rem; padding-right: 7.25rem }

.px-30 { padding-left: 7.5rem; padding-right: 7.5rem }

.px-31 { padding-left: 7.75rem; padding-right: 7.75rem }

/* Padding Y */

.py-0 { padding-top: 0rem; padding-bottom: 0rem }

.py-1 { padding-top: 0.25rem; padding-bottom: 0.25rem }

.py-2 { padding-top: 0.5rem; padding-bottom: 0.5rem }

.py-3 { padding-top: 0.75rem; padding-bottom: 0.75rem }

.py-4 { padding-top: 1rem; padding-bottom: 1rem }

.py-5 { padding-top: 1.25rem; padding-bottom: 1.25rem }

.py-6 { padding-top: 1.5rem; padding-bottom: 1.5rem }

.py-7 { padding-top: 1.75rem; padding-bottom: 1.75rem }

.py-8 { padding-top: 2rem; padding-bottom: 2rem }

.py-9 { padding-top: 2.25rem; padding-bottom: 2.25rem }

.py-10 { padding-top: 2.5rem; padding-bottom: 2.5rem }

.py-11 { padding-top: 2.75rem; padding-bottom: 2.75rem }

.py-12 { padding-top: 3rem; padding-bottom: 3rem }

.py-13 { padding-top: 3.25rem; padding-bottom: 3.25rem }

.py-14 { padding-top: 3.5rem; padding-bottom: 3.5rem }

.py-15 { padding-top: 3.75rem; padding-bottom: 3.75rem }

.py-16 { padding-top: 4rem; padding-bottom: 4rem }

.py-17 { padding-top: 4.25rem; padding-bottom: 4.25rem }

.py-18 { padding-top: 4.5rem; padding-bottom: 4.5rem }

.py-19 { padding-top: 4.75rem; padding-bottom: 4.75rem }

.py-20 { padding-top: 5rem; padding-bottom: 5rem }

.py-21 { padding-top: 5.25rem; padding-bottom: 5.25rem }

.py-22 { padding-top: 5.5rem; padding-bottom: 5.5rem }

.py-23 { padding-top: 5.75rem; padding-bottom: 5.75rem }

.py-24 { padding-top: 6rem; padding-bottom: 6rem }

.py-25 { padding-top: 6.25rem; padding-bottom: 6.25rem }

.py-26 { padding-top: 6.5rem; padding-bottom: 6.5rem }

.py-27 { padding-top: 6.75rem; padding-bottom: 6.75rem }

.py-28 { padding-top: 7rem; padding-bottom: 7rem }

.py-29 { padding-top: 7.25rem; padding-bottom: 7.25rem }

.py-30 { padding-top: 7.5rem; padding-bottom: 7.5rem }

.py-31 { padding-top: 7.75rem; padding-bottom: 7.75rem }

/* Padding Top */

.pt-0 { padding-top: 0rem }

.pt-1 { padding-top: 0.25rem }

.pt-2 { padding-top: 0.5rem }

.pt-3 { padding-top: 0.75rem }

.pt-4 { padding-top: 1rem }

.pt-5 { padding-top: 1.25rem }

.pt-6 { padding-top: 1.5rem }

.pt-7 { padding-top: 1.75rem }

.pt-8 { padding-top: 2rem }

.pt-9 { padding-top: 2.25rem }

.pt-10 { padding-top: 2.5rem }

.pt-11 { padding-top: 2.75rem }

.pt-12 { padding-top: 3rem }

.pt-13 { padding-top: 3.25rem }

.pt-14 { padding-top: 3.5rem }

.pt-15 { padding-top: 3.75rem }

.pt-16 { padding-top: 4rem }

.pt-17 { padding-top: 4.25rem }

.pt-18 { padding-top: 4.5rem }

.pt-19 { padding-top: 4.75rem }

.pt-20 { padding-top: 5rem }

.pt-21 { padding-top: 5.25rem }

.pt-22 { padding-top: 5.5rem }

.pt-23 { padding-top: 5.75rem }

.pt-24 { padding-top: 6rem }

.pt-25 { padding-top: 6.25rem }

.pt-26 { padding-top: 6.5rem }

.pt-27 { padding-top: 6.75rem }

.pt-28 { padding-top: 7rem }

.pt-29 { padding-top: 7.25rem }

.pt-30 { padding-top: 7.5rem }

.pt-31 { padding-top: 7.75rem }

/* Padding Right */

.pr-0 { padding-right: 0rem }

.pr-1 { padding-right: 0.25rem }

.pr-2 { padding-right: 0.5rem }

.pr-3 { padding-right: 0.75rem }

.pr-4 { padding-right: 1rem }

.pr-5 { padding-right: 1.25rem }

.pr-6 { padding-right: 1.5rem }

.pr-7 { padding-right: 1.75rem }

.pr-8 { padding-right: 2rem }

.pr-9 { padding-right: 2.25rem }

.pr-10 { padding-right: 2.5rem }

.pr-11 { padding-right: 2.75rem }

.pr-12 { padding-right: 3rem }

.pr-13 { padding-right: 3.25rem }

.pr-14 { padding-right: 3.5rem }

.pr-15 { padding-right: 3.75rem }

.pr-16 { padding-right: 4rem }

.pr-17 { padding-right: 4.25rem }

.pr-18 { padding-right: 4.5rem }

.pr-19 { padding-right: 4.75rem }

.pr-20 { padding-right: 5rem }

.pr-21 { padding-right: 5.25rem }

.pr-22 { padding-right: 5.5rem }

.pr-23 { padding-right: 5.75rem }

.pr-24 { padding-right: 6rem }

.pr-25 { padding-right: 6.25rem }

.pr-26 { padding-right: 6.5rem }

.pr-27 { padding-right: 6.75rem }

.pr-28 { padding-right: 7rem }

.pr-29 { padding-right: 7.25rem }

.pr-30 { padding-right: 7.5rem }

.pr-31 { padding-right: 7.75rem }

/* Padding Bottom */

.pb-0 { padding-bottom: 0rem }

.pb-1 { padding-bottom: 0.25rem }

.pb-2 { padding-bottom: 0.5rem }

.pb-3 { padding-bottom: 0.75rem }

.pb-4 { padding-bottom: 1rem }

.pb-5 { padding-bottom: 1.25rem }

.pb-6 { padding-bottom: 1.5rem }

.pb-7 { padding-bottom: 1.75rem }

.pb-8 { padding-bottom: 2rem }

.pb-9 { padding-bottom: 2.25rem }

.pb-10 { padding-bottom: 2.5rem }

.pb-11 { padding-bottom: 2.75rem }

.pb-12 { padding-bottom: 3rem }

.pb-13 { padding-bottom: 3.25rem }

.pb-14 { padding-bottom: 3.5rem }

.pb-15 { padding-bottom: 3.75rem }

.pb-16 { padding-bottom: 4rem }

.pb-17 { padding-bottom: 4.25rem }

.pb-18 { padding-bottom: 4.5rem }

.pb-19 { padding-bottom: 4.75rem }

.pb-20 { padding-bottom: 5rem }

.pb-21 { padding-bottom: 5.25rem }

.pb-22 { padding-bottom: 5.5rem }

.pb-23 { padding-bottom: 5.75rem }

.pb-24 { padding-bottom: 6rem }

.pb-25 { padding-bottom: 6.25rem }

.pb-26 { padding-bottom: 6.5rem }

.pb-27 { padding-bottom: 6.75rem }

.pb-28 { padding-bottom: 7rem }

.pb-29 { padding-bottom: 7.25rem }

.pb-30 { padding-bottom: 7.5rem }

.pb-31 { padding-bottom: 7.75rem }

/* Padding Left */

.pl-0 { padding-left: 0rem }

.pl-1 { padding-left: 0.25rem }

.pl-2 { padding-left: 0.5rem }

.pl-3 { padding-left: 0.75rem }

.pl-4 { padding-left: 1rem }

.pl-5 { padding-left: 1.25rem }

.pl-6 { padding-left: 1.5rem }

.pl-7 { padding-left: 1.75rem }

.pl-8 { padding-left: 2rem }

.pl-9 { padding-left: 2.25rem }

.pl-10 { padding-left: 2.5rem }

.pl-11 { padding-left: 2.75rem }

.pl-12 { padding-left: 3rem }

.pl-13 { padding-left: 3.25rem }

.pl-14 { padding-left: 3.5rem }

.pl-15 { padding-left: 3.75rem }

.pl-16 { padding-left: 4rem }

.pl-17 { padding-left: 4.25rem }

.pl-18 { padding-left: 4.5rem }

.pl-19 { padding-left: 4.75rem }

.pl-20 { padding-left: 5rem }

.pl-21 { padding-left: 5.25rem }

.pl-22 { padding-left: 5.5rem }

.pl-23 { padding-left: 5.75rem }

.pl-24 { padding-left: 6rem }

.pl-25 { padding-left: 6.25rem }

.pl-26 { padding-left: 6.5rem }

.pl-27 { padding-left: 6.75rem }

.pl-28 { padding-left: 7rem }

.pl-29 { padding-left: 7.25rem }

.pl-30 { padding-left: 7.5rem }

.pl-31 { padding-left: 7.75rem }

/* Text Transform */

.uppercase { text-transform: uppercase }

.lowercase { text-transform: lowercase }

.capitalize { text-transform: capitalize }

.normal-case { text-transform: none }

/* Font Weight */

.weight-1 { font-weight: 100 }

.weight-2 { font-weight: 200 }

.weight-3 { font-weight: 300 }

.weight-4 { font-weight: 400 }

.weight-5 { font-weight: 500 }

.weight-6 { font-weight: 600 }

.weight-7 { font-weight: 700 }

.weight-8 { font-weight: 800 }

.weight-9 { font-weight: 900 }

/* Font Size */

.text-0 { font-size: 0.75rem }

.text-1 { font-size: 0.875rem }

.text-2 { font-size: 1rem }

.text-3 { font-size: 1.25rem }

.text-4 { font-size: 1.5rem }

.text-5 { font-size: 1.75rem }

.text-6 { font-size: 2rem }

.text-7 { font-size: 2.25rem }

.text-8 { font-size: 2.5rem }

.text-9 { font-size: 2.75rem }

/* Text Align */

.text-left { text-align: left }

.text-center { text-align: center }

.text-right { text-align: right }

.text-justify { text-align: justify }

/* Text Colors */

.text-muted { color: var(--text-muted) }

/* Titles */

.title-1,
.title-2,
.title-3,
.title-4,
.title-5 {
  display: block;
  font-weight: 500;
  letter-spacing: -0.016em;
  color: var(--text-title)
}

.title-1 { font-size: 0.875rem }

.title-2 { font-size: 1rem }

.title-3 { font-size: 1.25rem }

.title-4 { font-size: 1.5rem }

.title-5 { font-size: 1.75rem }

.title-6 { font-size: 2rem; font-weight: 700 }

.title-7 { font-size: 2.25rem; font-weight: 700 }

.title-8 { font-size: 2.5rem; font-weight: 700 }

.title-9 { font-size: 2.75rem; font-weight: 700 }

.title-10 { font-size: 3rem; font-weight: 700 }

/* Whitespace */

.no-wrap { white-space: nowrap }

.pre { white-space: pre }

.pre-line { white-space: pre-line }

.pre-wrap { white-space: pre-wrap }

/* Truncate */

.truncate	{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}

/* Code */

.code {
  line-height: 1.5;
  font-family: Menlo, Monaco, 'Courier New', monospace;
  font-size: 12px;
}

/* Direction */

.flex-row { flex-direction: row }

.flex-row-reverse { flex-direction: row-reverse }

.flex-col { flex-direction: column }

.flex-col-reverse { flex-direction: column-reverse }

/* Wrap */

.flex-no-wrap { flex-wrap: nowrap }

.flex-wrap { flex-wrap: wrap }

.flex-wrap-reverse { flex-wrap: wrap-reverse }

/* Align Items */

.items-stretch { align-items: stretch }

.items-start { align-items: flex-start }

.items-center { align-items: center }

.items-end { align-items: flex-end }

.items-baseline { align-items: baseline }

/* Justify Items */

.justify-items-auto { justify-items: auto }

.justify-items-normal { justify-items: normal }

.justify-items-stretch { justify-items: stretch }

.justify-items-center { justify-items: center }

.justify-items-start { justify-items: start }

.justify-items-end { justify-items: end }

.justify-items-flex-start { justify-items: flex-start }

.justify-items-flex-end { justify-items: flex-end }

.justify-items-self-start { justify-items: self-start }

.justify-items-self-end { justify-items: self-end }

.justify-items-left { justify-items: left }

.justify-items-right { justify-items: right }

/* Align Content */

.content-start { align-content: flex-start }

.content-center { align-content: center }

.content-end { align-content: flex-end }

.content-between { align-content: space-between }

.content-around { align-content: space-around }

/* Justify Content */

.justify-start { justify-content: flex-start }

.justify-center { justify-content: center }

.justify-end { justify-content: flex-end }

.justify-between { justify-content: space-between }

.justify-around { justify-content: space-around }

.justify-flex-start { justify-content: flex-start }

.justify-flex-end { justify-content: flex-end }

/* Grow */

.flex-grow { flex-grow: 1 }

.flex-no-grow { flex-grow: 0 }

/* Shrink */

.flex-shrink { flex-shrink: 1 }

.flex-no-shrink { flex-shrink: 0 }

/* Helpers */

.flex-center {
  justify-content: center;
  align-items: center;
}

/* Border Radius */

.radius-1 { border-radius: 2px }

.radius-2 { border-radius: 4px }

.radius-3 { border-radius: 8px }

.radius-full { border-radius: 50% }

/* Borders */

.border { border: 1px solid var(--border) }

.border-top { border-top: 1px solid var(--border) }

.border-right { border-right: 1px solid var(--border) }

.border-bottom { border-bottom: 1px solid var(--border) }

.border-left { border-left: 1px solid var(--border) }

/* Smooth shadows: https://brumm.af/shadows */

/* Box Shadows */

.shadow-1 { box-shadow: var(--shadow-1) }

.shadow-2 { box-shadow: var(--shadow-2) }

.shadow-3 { box-shadow: var(--shadow-3) }

.shadow-4 { box-shadow: var(--shadow-4) }

/* Directional */

.shadow-top    { box-shadow: var(--shadow-top) }

.shadow-right  { box-shadow: var(--shadow-right) }

.shadow-bottom { box-shadow: var(--shadow-bottom) }

.shadow-left   { box-shadow: var(--shadow-left) }

/* Inset */

.inset { box-shadow: var(--shadow-inset) }

/* User Select */

.select-none { user-select: none }

.select-text { user-select: text }

.select-all { user-select: all }

.select-auto { user-select: auto }

/* Resize */

.resize-none { resize: none }

.resize { resize: both }

.resize-y { resize: vertical }

.resize-x { resize: horizontal }

/* Backgrounds */

.bg-0 { background: var(--background) }

.bg-1 { background: var(--neutral-0) }

.bg-2 { background: var(--neutral-1) }

.bg-3 { background: var(--neutral-2) }

.bg-4 { background: HSL(40, 16%, 96%) }

/* Hover Backgrounds */

.hover-bg-0:hover { background: var(--background) }

.hover-bg-1:hover { background: var(--neutral-0) }

.hover-bg-2:hover { background: var(--neutral-1) }

.hover-bg-3:hover { background: var(--neutral-2) }

.hover-bg-4:hover { background: HSL(40, 16%, 96%) }

/* Scrollbars */

.scrollbars-none::-webkit-scrollbar {
  width: 0;
  height: 0;
  background: transparent;
}

/* Cursors */

.cursor-alias { cursor: alias }

.cursor-all-scroll { cursor: all-scroll }

.cursor-auto { cursor: auto }

.cursor-cell { cursor: cell }

.cursor-context-menu { cursor: context-menu }

.cursor-col-resize { cursor: col-resize }

.cursor-copy { cursor: copy }

.cursor-crosshair { cursor: crosshair }

.cursor-default { cursor: default }

.cursor-e-resize { cursor: e-resize }

.cursor-ew-resize { cursor: ew-resize }

.cursor-grab { cursor: grab }

.cursor-grabbing { cursor: grabbing }

.cursor-help { cursor: help }

.cursor-move { cursor: move }

.cursor-n-resize { cursor: n-resize }

.cursor-ne-resize { cursor: ne-resize }

.cursor-nesw-resize { cursor: nesw-resize }

.cursor-ns-resize { cursor: ns-resize }

.cursor-nw-resize { cursor: nw-resize }

.cursor-nwse-resize { cursor: nwse-resize }

.cursor-no-drop { cursor: no-drop }

.cursor-none { cursor: none }

.cursor-not-allowed { cursor: not-allowed }

.cursor-pointer { cursor: pointer }

.cursor-progress { cursor: progress }

.cursor-row-resize { cursor: row-resize }

.cursor-s-resize { cursor: s-resize }

.cursor-se-resize { cursor: se-resize }

.cursor-sw-resize { cursor: sw-resize }

.cursor-text { cursor: text }

.cursor-w-resize { cursor: w-resize }

.cursor-wait { cursor: wait }

.cursor-zoom-in { cursor: zoom-in }

.cursor-zoom-out { cursor: zoom-out }

::-webkit-scrollbar {
  width: 16px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-fg);
  border-radius: 20px;
  border: 5px solid var(--scrollbar-bg);
}

/* @import "./themes/light/index.css"; */
body[data-theme="light"] .animate-show {
  opacity: 0;
  animation: fade-in 500ms cubic-bezier(0.0, 0.0, 0.2, 1) forwards;
}

body[data-theme="light"] .animate-hide {
  animation: fade-out 200ms cubic-bezier(0.0, 0.0, 0.2, 1) forwards;
}

@keyframes fade-in {
  from {
    opacity: 0;
    transform: translateY(1rem);
  }
  
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  
  to {
    opacity: 0;
    transform: translateY(10rem);
  }
}

body[data-theme="light"] {

  /*  General
   *  ─────────────────────────────────── */
  
    --oc-white: #ffffff;
    --oc-white-rgb: 255, 255, 255;
    --oc-black: #000000;
    --oc-black-rgb: 0, 0, 0;
  
  
  /*  Gray
   *  ─────────────────────────────────── */
  
    --oc-gray-0: #f8f9fa;
    --oc-gray-0-rgb: 248, 249, 250;
    --oc-gray-1: #f1f3f5;
    --oc-gray-1-rgb: 241, 243, 245;
    --oc-gray-2: #e9ecef;
    --oc-gray-2-rgb: 233, 236, 239;
    --oc-gray-3: #dee2e6;
    --oc-gray-3-rgb: 222, 226, 230;
    --oc-gray-4: #ced4da;
    --oc-gray-4-rgb: 206, 212, 218;
    --oc-gray-5: #adb5bd;
    --oc-gray-5-rgb: 173, 181, 189;
    --oc-gray-6: #868e96;
    --oc-gray-6-rgb: 134, 142, 150;
    --oc-gray-7: #495057;
    --oc-gray-7-rgb: 73, 80, 87;
    --oc-gray-8: #343a40;
    --oc-gray-8-rgb: 52, 58, 64;
    --oc-gray-9-rgb: 33, 37, 41;
    --oc-gray-9: #212529;
    --oc-gray-10: #101214;
    --oc-gray-11: #010101;
    --oc-gray-12: #000000;
  
  
  /*  Red
   *  ─────────────────────────────────── */
  
    --oc-red-0: #fff5f5;
    --oc-red-0-rgb: 255, 245, 245;
    --oc-red-1: #ffe3e3;
    --oc-red-1-rgb: 255, 227, 227;
    --oc-red-2: #ffc9c9;
    --oc-red-2-rgb: 255, 201, 201;
    --oc-red-3: #ffa8a8;
    --oc-red-3-rgb: 255, 168, 168;
    --oc-red-4: #ff8787;
    --oc-red-4-rgb: 255, 135, 135;
    --oc-red-5: #ff6b6b;
    --oc-red-5-rgb: 255, 107, 107;
    --oc-red-6: #fa5252;
    --oc-red-6-rgb: 250, 82, 82;
    --oc-red-7: #f03e3e;
    --oc-red-7-rgb: 240, 62, 62;
    --oc-red-8: #e03131;
    --oc-red-8-rgb: 224, 49, 49;
    --oc-red-9: #c92a2a;
    --oc-red-9-rgb: 201, 42, 42;
  
  
  /*  Pink
   *  ─────────────────────────────────── */
  
    --oc-pink-0: #fff0f6;
    --oc-pink-0-rgb: 255, 240, 246;
    --oc-pink-1: #ffdeeb;
    --oc-pink-1-rgb: 255, 222, 235;
    --oc-pink-2: #fcc2d7;
    --oc-pink-2-rgb: 252, 194, 215;
    --oc-pink-3: #faa2c1;
    --oc-pink-3-rgb: 250, 162, 193;
    --oc-pink-4: #f783ac;
    --oc-pink-4-rgb: 247, 131, 172;
    --oc-pink-5: #f06595;
    --oc-pink-5-rgb: 240, 101, 149;
    --oc-pink-6: #e64980;
    --oc-pink-6-rgb: 230, 73, 128;
    --oc-pink-7: #d6336c;
    --oc-pink-7-rgb: 214, 51, 108;
    --oc-pink-8: #c2255c;
    --oc-pink-8-rgb: 194, 37, 92;
    --oc-pink-9: #a61e4d;
    --oc-pink-9-rgb: 166, 30, 77;
  
  
  /*  Grape
   *  ─────────────────────────────────── */
  
    --oc-grape-0: #f8f0fc;
    --oc-grape-0-rgb: 248, 240, 252;
    --oc-grape-1: #f3d9fa;
    --oc-grape-1-rgb: 243, 217, 250;
    --oc-grape-2: #eebefa;
    --oc-grape-2-rgb: 238, 190, 250;
    --oc-grape-3: #e599f7;
    --oc-grape-3-rgb: 229, 153, 247;
    --oc-grape-4: #da77f2;
    --oc-grape-4-rgb: 218, 119, 242;
    --oc-grape-5: #cc5de8;
    --oc-grape-5-rgb: 204, 93, 232;
    --oc-grape-6: #be4bdb;
    --oc-grape-6-rgb: 190, 75, 219;
    --oc-grape-7: #ae3ec9;
    --oc-grape-7-rgb: 174, 62, 201;
    --oc-grape-8: #9c36b5;
    --oc-grape-8-rgb: 156, 54, 181;
    --oc-grape-9: #862e9c;
    --oc-grape-9-rgb: 134, 46, 156;
  
  
  /*  Violet
   *  ─────────────────────────────────── */
  
    --oc-violet-0: #f3f0ff;
    --oc-violet-0-rgb: 243, 240, 255;
    --oc-violet-1: #e5dbff;
    --oc-violet-1-rgb: 229, 219, 255;
    --oc-violet-2: #d0bfff;
    --oc-violet-2-rgb: 208, 191, 255;
    --oc-violet-3: #b197fc;
    --oc-violet-3-rgb: 177, 151, 252;
    --oc-violet-4: #9775fa;
    --oc-violet-4-rgb: 151, 117, 250;
    --oc-violet-5: #845ef7;
    --oc-violet-5-rgb: 132, 94, 247;
    --oc-violet-6: #7950f2;
    --oc-violet-6-rgb: 121, 80, 242;
    --oc-violet-7: #7048e8;
    --oc-violet-7-rgb: 112, 72, 232;
    --oc-violet-8: #6741d9;
    --oc-violet-8-rgb: 103, 65, 217;
    --oc-violet-9: #5f3dc4;
    --oc-violet-9-rgb: 95, 61, 196;
  
  
  /*  Indigo
   *  ─────────────────────────────────── */
  
    --oc-indigo-0: #edf2ff;
    --oc-indigo-0-rgb: 237, 242, 255;
    --oc-indigo-1: #dbe4ff;
    --oc-indigo-1-rgb: 219, 228, 255;
    --oc-indigo-2: #bac8ff;
    --oc-indigo-2-rgb: 186, 200, 255;
    --oc-indigo-3: #91a7ff;
    --oc-indigo-3-rgb: 145, 167, 255;
    --oc-indigo-4: #748ffc;
    --oc-indigo-4-rgb: 116, 143, 252;
    --oc-indigo-5: #5c7cfa;
    --oc-indigo-5-rgb: 92, 124, 250;
    --oc-indigo-6: #4c6ef5;
    --oc-indigo-6-rgb: 76, 110, 245;
    --oc-indigo-7: #4263eb;
    --oc-indigo-7-rgb: 66, 99, 235;
    --oc-indigo-8: #3b5bdb;
    --oc-indigo-8-rgb: 59, 91, 219;
    --oc-indigo-9: #364fc7;
    --oc-indigo-9-rgb: 54, 79, 199;
  
  
  /*  Blue
   *  ─────────────────────────────────── */
  
    --oc-blue-0: #e7f5ff;
    --oc-blue-0-rgb: 231, 245, 255;
    --oc-blue-1: #d0ebff;
    --oc-blue-1-rgb: 208, 235, 255;
    --oc-blue-2: #a5d8ff;
    --oc-blue-2-rgb: 165, 216, 255;
    --oc-blue-3: #74c0fc;
    --oc-blue-3-rgb: 116, 192, 252;
    --oc-blue-4: #4dabf7;
    --oc-blue-4-rgb: 77, 171, 247;
    --oc-blue-5: #339af0;
    --oc-blue-5-rgb: 51, 154, 240;
    --oc-blue-6: #228be6;
    --oc-blue-6-rgb: 34, 139, 230;
    --oc-blue-7: #1c7ed6;
    --oc-blue-7-rgb: 28, 126, 214;
    --oc-blue-8: #1971c2;
    --oc-blue-8-rgb: 25, 113, 194;
    --oc-blue-9: #1864ab;
    --oc-blue-9-rgb: 24, 100, 171;
  
  
  /*  Cyan
   *  ─────────────────────────────────── */
  
    --oc-cyan-0: #e3fafc;
    --oc-cyan-0-rgb: 227, 250, 252;
    --oc-cyan-1: #c5f6fa;
    --oc-cyan-1-rgb: 197, 246, 250;
    --oc-cyan-2: #99e9f2;
    --oc-cyan-2-rgb: 153, 233, 242;
    --oc-cyan-3: #66d9e8;
    --oc-cyan-3-rgb: 102, 217, 232;
    --oc-cyan-4: #3bc9db;
    --oc-cyan-4-rgb: 59, 201, 219;
    --oc-cyan-5: #22b8cf;
    --oc-cyan-5-rgb: 34, 184, 207;
    --oc-cyan-6: #15aabf;
    --oc-cyan-6-rgb: 21, 170, 191;
    --oc-cyan-7: #1098ad;
    --oc-cyan-7-rgb: 16, 152, 173;
    --oc-cyan-8: #0c8599;
    --oc-cyan-8-rgb: 12, 133, 153;
    --oc-cyan-9: #0b7285;
    --oc-cyan-9-rgb: 11, 114, 133;
  
  
  /*  Teal
   *  ─────────────────────────────────── */
  
    --oc-teal-0: #e6fcf5;
    --oc-teal-0-rgb: 230, 252, 245;
    --oc-teal-1: #c3fae8;
    --oc-teal-1-rgb: 195, 250, 232;
    --oc-teal-2: #96f2d7;
    --oc-teal-2-rgb: 150, 242, 215;
    --oc-teal-3: #63e6be;
    --oc-teal-3-rgb: 99, 230, 190;
    --oc-teal-4: #38d9a9;
    --oc-teal-4-rgb: 56, 217, 169;
    --oc-teal-5: #20c997;
    --oc-teal-5-rgb: 32, 201, 151;
    --oc-teal-6: #12b886;
    --oc-teal-6-rgb: 18, 184, 134;
    --oc-teal-7: #0ca678;
    --oc-teal-7-rgb: 12, 166, 120;
    --oc-teal-8: #099268;
    --oc-teal-8-rgb: 9, 146, 104;
    --oc-teal-9: #087f5b;
    --oc-teal-9-rgb: 8, 127, 91;
  
  
  /*  Green
   *  ─────────────────────────────────── */
  
    --oc-green-0: #ebfbee;
    --oc-green-0-rgb: 235, 251, 238;
    --oc-green-1: #d3f9d8;
    --oc-green-1-rgb: 211, 249, 216;
    --oc-green-2: #b2f2bb;
    --oc-green-2-rgb: 178, 242, 187;
    --oc-green-3: #8ce99a;
    --oc-green-3-rgb: 140, 233, 154;
    --oc-green-4: #69db7c;
    --oc-green-4-rgb: 105, 219, 124;
    --oc-green-5: #51cf66;
    --oc-green-5-rgb: 81, 207, 102;
    --oc-green-6: #40c057;
    --oc-green-6-rgb: 64, 192, 87;
    --oc-green-7: #37b24d;
    --oc-green-7-rgb: 55, 178, 77;
    --oc-green-8: #2f9e44;
    --oc-green-8-rgb: 47, 158, 68;
    --oc-green-9: #2b8a3e;
    --oc-green-9-rgb: 43, 138, 62;
  
  
  /*  Lime
   *  ─────────────────────────────────── */
  
    --oc-lime-0: #f4fce3;
    --oc-lime-0-rgb: 244, 252, 227;
    --oc-lime-1: #e9fac8;
    --oc-lime-1-rgb: 233, 250, 200;
    --oc-lime-2: #d8f5a2;
    --oc-lime-2-rgb: 216, 245, 162;
    --oc-lime-3: #c0eb75;
    --oc-lime-3-rgb: 192, 235, 117;
    --oc-lime-4: #a9e34b;
    --oc-lime-4-rgb: 169, 227, 75;
    --oc-lime-5: #94d82d;
    --oc-lime-5-rgb: 148, 216, 45;
    --oc-lime-6: #82c91e;
    --oc-lime-6-rgb: 130, 201, 30;
    --oc-lime-7: #74b816;
    --oc-lime-7-rgb: 116, 184, 22;
    --oc-lime-8: #66a80f;
    --oc-lime-8-rgb: 102, 168, 15;
    --oc-lime-9: #5c940d;
    --oc-lime-9-rgb: 92, 148, 13;
  
  
  /*  Yellow
   *  ─────────────────────────────────── */
  
    --oc-yellow-0: #fff9db;
    --oc-yellow-0-rgb: 255, 249, 219;
    --oc-yellow-1: #fff3bf;
    --oc-yellow-1-rgb: 255, 243, 191;
    --oc-yellow-2: #ffec99;
    --oc-yellow-2-rgb: 255, 236, 153;
    --oc-yellow-3: #ffe066;
    --oc-yellow-3-rgb: 255, 224, 102;
    --oc-yellow-4: #ffd43b;
    --oc-yellow-4-rgb: 255, 212, 59;
    --oc-yellow-5: #fcc419;
    --oc-yellow-5-rgb: 252, 196, 25;
    --oc-yellow-6: #fab005;
    --oc-yellow-6-rgb: 250, 176, 5;
    --oc-yellow-7: #f59f00;
    --oc-yellow-7-rgb: 245, 159, 0;
    --oc-yellow-8: #f08c00;
    --oc-yellow-8-rgb: 240, 140, 0;
    --oc-yellow-9: #e67700;
    --oc-yellow-9-rgb: 230, 119, 0;
  
  
  /*  Orange
   *  ─────────────────────────────────── */
  
    --oc-orange-0: #fff4e6;
    --oc-orange-0-rgb: 255, 244, 230;
    --oc-orange-1: #ffe8cc;
    --oc-orange-1-rgb: 255, 232, 204;
    --oc-orange-2: #ffd8a8;
    --oc-orange-2-rgb: 255, 216, 168;
    --oc-orange-3: #ffc078;
    --oc-orange-3-rgb: 255, 192, 120;
    --oc-orange-4: #ffa94d;
    --oc-orange-4-rgb: 255, 169, 77;
    --oc-orange-5: #ff922b;
    --oc-orange-5-rgb: 255, 146, 43;
    --oc-orange-6: #fd7e14;
    --oc-orange-6-rgb: 253, 126, 20;
    --oc-orange-7: #f76707;
    --oc-orange-7-rgb: 247, 103, 7;
    --oc-orange-8: #e8590c;
    --oc-orange-8-rgb: 232, 89, 12;
    --oc-orange-9: #d9480f;
    --oc-orange-9-rgb: 217, 72, 15;
  
  }

/* Neutral */

body[data-theme="light"] {
  --neutral-0: var(--oc-gray-0);
  --neutral-1: var(--oc-gray-1);
  --neutral-2: var(--oc-gray-2);
  --neutral-3: var(--oc-gray-3);
  --neutral-4: var(--oc-gray-4);
  --neutral-5: var(--oc-gray-5);
  --neutral-6: var(--oc-gray-6);
  --neutral-7: var(--oc-gray-7);
  --neutral-8: var(--oc-gray-8);
  --neutral-9: var(--oc-gray-9);
  --neutral-10: var(--oc-gray-10);
  --neutral-11: var(--oc-gray-11);
  --neutral-12: var(--oc-gray-12);
}

/* Primary */

body[data-theme="light"] {
  --primary-hue: 228;
  --primary-0: hsl(var(--primary-hue), 100%, 96%);
  --primary-1: hsl(var(--primary-hue), 94%, 93%);
  --primary-2: hsl(var(--primary-hue), 94%, 86%);
  --primary-3: hsl(var(--primary-hue), 95%, 78%);
  --primary-4: hsl(var(--primary-hue), 92%, 72%); 
  --primary-5: hsl(var(--primary-hue), 91%, 67%);
  --primary-6: hsl(var(--primary-hue), 86%, 63%);
  --primary-7: hsl(var(--primary-hue), 78%, 59%);
  --primary-8: hsl(var(--primary-hue), 66%, 55%);
  --primary-9: hsl(var(--primary-hue), 56%, 49%);
}

/* Danger */

body[data-theme="light"] {
  --danger-hue: 350;
  --danger-0: hsl(var(--danger-hue), 100%, 96%);
  --danger-1: hsl(var(--danger-hue), 94%, 93%);
  --danger-2: hsl(var(--danger-hue), 94%, 86%);
  --danger-3: hsl(var(--danger-hue), 95%, 78%);
  --danger-4: hsl(var(--danger-hue), 92%, 72%);
  --danger-5: hsl(var(--danger-hue), 91%, 67%);
  --danger-6: hsl(var(--danger-hue), 86%, 63%);
  --danger-7: hsl(var(--danger-hue), 78%, 59%);
  --danger-8: hsl(var(--danger-hue), 75%, 55%);
  --danger-9: hsl(var(--danger-hue), 75%, 49%);
  --danger-shadow: 0 0 0 3px var(--danger-2);
}

/* Warning */

body[data-theme="light"] {
  --warning-hue: 34;
  --warning-0: hsl(var(--warning-hue), 100%, 96%);
  --warning-1: hsl(var(--warning-hue), 94%, 93%);
  --warning-2: hsl(var(--warning-hue), 94%, 86%);
  --warning-3: hsl(var(--warning-hue), 95%, 78%);
  --warning-4: hsl(var(--warning-hue), 92%, 72%);
  --warning-5: hsl(var(--warning-hue), 91%, 67%);
  --warning-6: hsl(var(--warning-hue), 93%, 63%);
  --warning-7: hsl(var(--warning-hue), 93%, 59%);
  --warning-8: hsl(var(--warning-hue), 93%, 55%);
  --warning-9: hsl(var(--warning-hue), 93%, 49%);
}

/* Success */

body[data-theme="light"] {
  --success-hue: 205;
  --success-0: hsl(var(--success-hue), 100%, 96%);
  --success-1: hsl(var(--success-hue), 94%, 93%);
  --success-2: hsl(var(--success-hue), 94%, 86%);
  --success-3: hsl(var(--success-hue), 95%, 78%);
  --success-4: hsl(var(--success-hue), 92%, 72%);
  --success-5: hsl(var(--success-hue), 91%, 67%);
  --success-6: hsl(var(--success-hue), 90%, 63%);
  --success-7: hsl(var(--success-hue), 85%, 59%);
  --success-8: hsl(var(--success-hue), 90%, 55%);
  --success-9: hsl(var(--success-hue), 97%, 49%);
}

/* Primaries */

body[data-theme="light"] {
  --background: white;
  --foreground: var(--neutral-12);
}

/* Borders */

body[data-theme="light"] {
  --border: var(--neutral-2);
}

/* Text */

body[data-theme="light"] {
  --text-muted: var(--neutral-6);
  --text-placeholder: var(--neutral-6);
}

/* Inputs */

body[data-theme="light"] {
  --input-border: var(--neutral-2);
  --input-disabled-background: var(--neutral-1); 
  --input-disabled-foreground: var(--neutral-6);
  --input-focus-shadow: rgba(99, 114, 249, 0.14) 0px 0px 2px inset
    , var(--oc-indigo-2) 0px 0px 0px 1px inset
    , rgba(var(--oc-indigo-2-rgb), 0.15) 0px 0px 0px 3px;
}

/* Special Cases */

body[data-theme="light"] {
  --purple-shadow:rgba(99, 114, 249, 0.4) 0px 0px 0px 3px;
}

/* Scrollbars */

body[data-theme="light"] {
  --scrollbar-fg: var(--neutral-3);
  --scrollbar-bg: var(--neutral-0);
}

body[data-theme="light"] {
  --shadow-1: 0 0 0 1px rgba(50,50,93,.075), 0 0 1px #e9ecef, 0 2px 4px -2px rgba(138, 141, 151, 0.6);
  --shadow-2: 0 0 0 1px rgba(50,50,93,.075), 0 0 1px #e9ecef, 0 4px 8px -4px rgba(138, 141, 151, 0.6);
  --shadow-3: 0 0 0 1px rgba(50,50,93,.075), 0 0 1px #e9ecef, 0 8px 16px -8px rgba(138, 141, 151, 0.6);
  --shadow-4: 0 0 0 1px rgba(50,50,93,.075), 0 0 1px #e9ecef, 0 16px 32px -16px rgba(138, 141, 151, 0.6);
  
  --shadow-top: rgb(240, 240, 240) 0px -5px 5px -5px;
  --shadow-right: rgb(240, 240, 240) 5px 0 5px -5px;
  --shadow-bottom: rgb(240, 240, 240) 0px 5px 5px -5px;
  --shadow-left: rgb(240, 240, 240) -5px 0 5px -5px;

  --shadow-inset: inset 0 3px 8px -5px var(--neutral-4);
}

/* Default */

body[data-theme="light"] .Button.default {
  border: 1px solid var(--input-border);
  background: var(--background);
  color: var(--neutral-8);
}

body[data-theme="light"] .Button.default.hover,
body[data-theme="light"] .Button.default:hover {
  background: var(--neutral-1);
}

body[data-theme="light"] .Button.default.active,
body[data-theme="light"] .Button.default:active {
  background: var(--neutral-2);
}

body[data-theme="light"] .Button.default.focus,
body[data-theme="light"] .Button.default:focus {
  border-color: transparent;
  box-shadow: var(--input-focus-shadow);
}

body[data-theme="light"] .Button.default.disabled {
  border: 1px solid var(--input-disabled-background);
  background: var(--input-disabled-background);
  color: var(--input-disabled-foreground);
}

/* Primary */

body[data-theme="light"] .Button.primary {
  border: 1px solid transparent;
  background: var(--primary-6);
  color: var(--background);
}

body[data-theme="light"] .Button.primary.hover,
body[data-theme="light"] .Button.primary:hover {
  background: var(--primary-5);
}

body[data-theme="light"] .Button.primary.active,
body[data-theme="light"] .Button.primary:active {
  background: var(--primary-7);
}

body[data-theme="light"] .Button.primary.focus,
body[data-theme="light"] .Button.primary:focus {
  box-shadow: var(--purple-shadow);
}

body[data-theme="light"] .Button.primary.disabled {
  color: rgba(255,255,255,.5);
}

body[data-theme="light"] .Button.primary.pending::before {
  --color: rgba(255, 255, 255, 0.75);
}

/* Danger */

body[data-theme="light"] .Button.danger {
  border: 1px solid transparent;
  background: var(--danger-8);
  color: var(--background);
}

body[data-theme="light"] .Button.danger.hover,
body[data-theme="light"] .Button.danger:hover {
  background: var(--danger-7);
}

body[data-theme="light"] .Button.danger.active,
body[data-theme="light"] .Button.danger:active {
  background: var(--danger-9);
}

body[data-theme="light"] .Button.danger.focus,
body[data-theme="light"] .Button.danger:focus {
  box-shadow: var(--danger-shadow);
}

body[data-theme="light"] .Button.danger.disabled {
  color: rgba(255,255,255,.5);
}

body[data-theme="light"] .Button.danger.pending::before {
  --color: rgba(255, 255, 255, 0.75);
}

/* Minimal */

body[data-theme="light"] .Button.minimal {
  background: transparent;
  color: var(--foreground);
}

body[data-theme="light"] .Button.minimal.hover,
body[data-theme="light"] .Button.minimal:hover {
  background: var(--neutral-1);
}

body[data-theme="light"] .Button.minimal.active,
body[data-theme="light"] .Button.minimal:active {
  background: var(--neutral-2);
}

body[data-theme="light"] .Button.minimal.focus,
body[data-theme="light"] .Button.minimal:focus {
  border-color: transparent;
  box-shadow: var(--input-focus-shadow);
}

body[data-theme="light"] .Button.minimal.disabled {
  border: 1px solid var(--input-disabled-background);
  background: var(--input-disabled-background);
  color: var(--input-disabled-foreground);
}
body[data-theme="dark"] .animate-show {
  opacity: 0;
  animation: fade-in 500ms cubic-bezier(0.0, 0.0, 0.2, 1) forwards;
}

body[data-theme="dark"] .animate-hide {
  animation: fade-out 200ms cubic-bezier(0.0, 0.0, 0.2, 1) forwards;
}

@keyframes fade-in {
  from {
    opacity: 0;
    transform: translateY(1rem);
  }
  
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  
  to {
    opacity: 0;
    transform: translateY(10rem);
  }
}

body[data-theme="dark"] {

  /*  General
   *  ─────────────────────────────────── */
  
    --oc-white: #ffffff;
    --oc-white-rgb: 255, 255, 255;
    --oc-black: #000000;
    --oc-black-rgb: 0, 0, 0;
  
  
  /*  Gray
   *  ─────────────────────────────────── */
  
    --oc-gray-0: #f8f9fa;
    --oc-gray-0-rgb: 248, 249, 250;
    --oc-gray-1: #f1f3f5;
    --oc-gray-1-rgb: 241, 243, 245;
    --oc-gray-2: #e9ecef;
    --oc-gray-2-rgb: 233, 236, 239;
    --oc-gray-3: #dee2e6;
    --oc-gray-3-rgb: 222, 226, 230;
    --oc-gray-4: #ced4da;
    --oc-gray-4-rgb: 206, 212, 218;
    --oc-gray-5: #adb5bd;
    --oc-gray-5-rgb: 173, 181, 189;
    --oc-gray-6: #868e96;
    --oc-gray-6-rgb: 134, 142, 150;
    --oc-gray-7: #495057;
    --oc-gray-7-rgb: 73, 80, 87;
    --oc-gray-8: #343a40;
    --oc-gray-8-rgb: 52, 58, 64;
    --oc-gray-9-rgb: 33, 37, 41;
    --oc-gray-9: #212529;
    --oc-gray-10: #101214;
    --oc-gray-11: #010101;
    --oc-gray-12: #000000;
  
  
  /*  Red
   *  ─────────────────────────────────── */
  
    --oc-red-0: #fff5f5;
    --oc-red-0-rgb: 255, 245, 245;
    --oc-red-1: #ffe3e3;
    --oc-red-1-rgb: 255, 227, 227;
    --oc-red-2: #ffc9c9;
    --oc-red-2-rgb: 255, 201, 201;
    --oc-red-3: #ffa8a8;
    --oc-red-3-rgb: 255, 168, 168;
    --oc-red-4: #ff8787;
    --oc-red-4-rgb: 255, 135, 135;
    --oc-red-5: #ff6b6b;
    --oc-red-5-rgb: 255, 107, 107;
    --oc-red-6: #fa5252;
    --oc-red-6-rgb: 250, 82, 82;
    --oc-red-7: #f03e3e;
    --oc-red-7-rgb: 240, 62, 62;
    --oc-red-8: #e03131;
    --oc-red-8-rgb: 224, 49, 49;
    --oc-red-9: #c92a2a;
    --oc-red-9-rgb: 201, 42, 42;
  
  
  /*  Pink
   *  ─────────────────────────────────── */
  
    --oc-pink-0: #fff0f6;
    --oc-pink-0-rgb: 255, 240, 246;
    --oc-pink-1: #ffdeeb;
    --oc-pink-1-rgb: 255, 222, 235;
    --oc-pink-2: #fcc2d7;
    --oc-pink-2-rgb: 252, 194, 215;
    --oc-pink-3: #faa2c1;
    --oc-pink-3-rgb: 250, 162, 193;
    --oc-pink-4: #f783ac;
    --oc-pink-4-rgb: 247, 131, 172;
    --oc-pink-5: #f06595;
    --oc-pink-5-rgb: 240, 101, 149;
    --oc-pink-6: #e64980;
    --oc-pink-6-rgb: 230, 73, 128;
    --oc-pink-7: #d6336c;
    --oc-pink-7-rgb: 214, 51, 108;
    --oc-pink-8: #c2255c;
    --oc-pink-8-rgb: 194, 37, 92;
    --oc-pink-9: #a61e4d;
    --oc-pink-9-rgb: 166, 30, 77;
  
  
  /*  Grape
   *  ─────────────────────────────────── */
  
    --oc-grape-0: #f8f0fc;
    --oc-grape-0-rgb: 248, 240, 252;
    --oc-grape-1: #f3d9fa;
    --oc-grape-1-rgb: 243, 217, 250;
    --oc-grape-2: #eebefa;
    --oc-grape-2-rgb: 238, 190, 250;
    --oc-grape-3: #e599f7;
    --oc-grape-3-rgb: 229, 153, 247;
    --oc-grape-4: #da77f2;
    --oc-grape-4-rgb: 218, 119, 242;
    --oc-grape-5: #cc5de8;
    --oc-grape-5-rgb: 204, 93, 232;
    --oc-grape-6: #be4bdb;
    --oc-grape-6-rgb: 190, 75, 219;
    --oc-grape-7: #ae3ec9;
    --oc-grape-7-rgb: 174, 62, 201;
    --oc-grape-8: #9c36b5;
    --oc-grape-8-rgb: 156, 54, 181;
    --oc-grape-9: #862e9c;
    --oc-grape-9-rgb: 134, 46, 156;
  
  
  /*  Violet
   *  ─────────────────────────────────── */
  
    --oc-violet-0: #f3f0ff;
    --oc-violet-0-rgb: 243, 240, 255;
    --oc-violet-1: #e5dbff;
    --oc-violet-1-rgb: 229, 219, 255;
    --oc-violet-2: #d0bfff;
    --oc-violet-2-rgb: 208, 191, 255;
    --oc-violet-3: #b197fc;
    --oc-violet-3-rgb: 177, 151, 252;
    --oc-violet-4: #9775fa;
    --oc-violet-4-rgb: 151, 117, 250;
    --oc-violet-5: #845ef7;
    --oc-violet-5-rgb: 132, 94, 247;
    --oc-violet-6: #7950f2;
    --oc-violet-6-rgb: 121, 80, 242;
    --oc-violet-7: #7048e8;
    --oc-violet-7-rgb: 112, 72, 232;
    --oc-violet-8: #6741d9;
    --oc-violet-8-rgb: 103, 65, 217;
    --oc-violet-9: #5f3dc4;
    --oc-violet-9-rgb: 95, 61, 196;
  
  
  /*  Indigo
   *  ─────────────────────────────────── */
  
    --oc-indigo-0: #edf2ff;
    --oc-indigo-0-rgb: 237, 242, 255;
    --oc-indigo-1: #dbe4ff;
    --oc-indigo-1-rgb: 219, 228, 255;
    --oc-indigo-2: #bac8ff;
    --oc-indigo-2-rgb: 186, 200, 255;
    --oc-indigo-3: #91a7ff;
    --oc-indigo-3-rgb: 145, 167, 255;
    --oc-indigo-4: #748ffc;
    --oc-indigo-4-rgb: 116, 143, 252;
    --oc-indigo-5: #5c7cfa;
    --oc-indigo-5-rgb: 92, 124, 250;
    --oc-indigo-6: #4c6ef5;
    --oc-indigo-6-rgb: 76, 110, 245;
    --oc-indigo-7: #4263eb;
    --oc-indigo-7-rgb: 66, 99, 235;
    --oc-indigo-8: #3b5bdb;
    --oc-indigo-8-rgb: 59, 91, 219;
    --oc-indigo-9: #364fc7;
    --oc-indigo-9-rgb: 54, 79, 199;
  
  
  /*  Blue
   *  ─────────────────────────────────── */
  
    --oc-blue-0: #e7f5ff;
    --oc-blue-0-rgb: 231, 245, 255;
    --oc-blue-1: #d0ebff;
    --oc-blue-1-rgb: 208, 235, 255;
    --oc-blue-2: #a5d8ff;
    --oc-blue-2-rgb: 165, 216, 255;
    --oc-blue-3: #74c0fc;
    --oc-blue-3-rgb: 116, 192, 252;
    --oc-blue-4: #4dabf7;
    --oc-blue-4-rgb: 77, 171, 247;
    --oc-blue-5: #339af0;
    --oc-blue-5-rgb: 51, 154, 240;
    --oc-blue-6: #228be6;
    --oc-blue-6-rgb: 34, 139, 230;
    --oc-blue-7: #1c7ed6;
    --oc-blue-7-rgb: 28, 126, 214;
    --oc-blue-8: #1971c2;
    --oc-blue-8-rgb: 25, 113, 194;
    --oc-blue-9: #1864ab;
    --oc-blue-9-rgb: 24, 100, 171;
  
  
  /*  Cyan
   *  ─────────────────────────────────── */
  
    --oc-cyan-0: #e3fafc;
    --oc-cyan-0-rgb: 227, 250, 252;
    --oc-cyan-1: #c5f6fa;
    --oc-cyan-1-rgb: 197, 246, 250;
    --oc-cyan-2: #99e9f2;
    --oc-cyan-2-rgb: 153, 233, 242;
    --oc-cyan-3: #66d9e8;
    --oc-cyan-3-rgb: 102, 217, 232;
    --oc-cyan-4: #3bc9db;
    --oc-cyan-4-rgb: 59, 201, 219;
    --oc-cyan-5: #22b8cf;
    --oc-cyan-5-rgb: 34, 184, 207;
    --oc-cyan-6: #15aabf;
    --oc-cyan-6-rgb: 21, 170, 191;
    --oc-cyan-7: #1098ad;
    --oc-cyan-7-rgb: 16, 152, 173;
    --oc-cyan-8: #0c8599;
    --oc-cyan-8-rgb: 12, 133, 153;
    --oc-cyan-9: #0b7285;
    --oc-cyan-9-rgb: 11, 114, 133;
  
  
  /*  Teal
   *  ─────────────────────────────────── */
  
    --oc-teal-0: #e6fcf5;
    --oc-teal-0-rgb: 230, 252, 245;
    --oc-teal-1: #c3fae8;
    --oc-teal-1-rgb: 195, 250, 232;
    --oc-teal-2: #96f2d7;
    --oc-teal-2-rgb: 150, 242, 215;
    --oc-teal-3: #63e6be;
    --oc-teal-3-rgb: 99, 230, 190;
    --oc-teal-4: #38d9a9;
    --oc-teal-4-rgb: 56, 217, 169;
    --oc-teal-5: #20c997;
    --oc-teal-5-rgb: 32, 201, 151;
    --oc-teal-6: #12b886;
    --oc-teal-6-rgb: 18, 184, 134;
    --oc-teal-7: #0ca678;
    --oc-teal-7-rgb: 12, 166, 120;
    --oc-teal-8: #099268;
    --oc-teal-8-rgb: 9, 146, 104;
    --oc-teal-9: #087f5b;
    --oc-teal-9-rgb: 8, 127, 91;
  
  
  /*  Green
   *  ─────────────────────────────────── */
  
    --oc-green-0: #ebfbee;
    --oc-green-0-rgb: 235, 251, 238;
    --oc-green-1: #d3f9d8;
    --oc-green-1-rgb: 211, 249, 216;
    --oc-green-2: #b2f2bb;
    --oc-green-2-rgb: 178, 242, 187;
    --oc-green-3: #8ce99a;
    --oc-green-3-rgb: 140, 233, 154;
    --oc-green-4: #69db7c;
    --oc-green-4-rgb: 105, 219, 124;
    --oc-green-5: #51cf66;
    --oc-green-5-rgb: 81, 207, 102;
    --oc-green-6: #40c057;
    --oc-green-6-rgb: 64, 192, 87;
    --oc-green-7: #37b24d;
    --oc-green-7-rgb: 55, 178, 77;
    --oc-green-8: #2f9e44;
    --oc-green-8-rgb: 47, 158, 68;
    --oc-green-9: #2b8a3e;
    --oc-green-9-rgb: 43, 138, 62;
  
  
  /*  Lime
   *  ─────────────────────────────────── */
  
    --oc-lime-0: #f4fce3;
    --oc-lime-0-rgb: 244, 252, 227;
    --oc-lime-1: #e9fac8;
    --oc-lime-1-rgb: 233, 250, 200;
    --oc-lime-2: #d8f5a2;
    --oc-lime-2-rgb: 216, 245, 162;
    --oc-lime-3: #c0eb75;
    --oc-lime-3-rgb: 192, 235, 117;
    --oc-lime-4: #a9e34b;
    --oc-lime-4-rgb: 169, 227, 75;
    --oc-lime-5: #94d82d;
    --oc-lime-5-rgb: 148, 216, 45;
    --oc-lime-6: #82c91e;
    --oc-lime-6-rgb: 130, 201, 30;
    --oc-lime-7: #74b816;
    --oc-lime-7-rgb: 116, 184, 22;
    --oc-lime-8: #66a80f;
    --oc-lime-8-rgb: 102, 168, 15;
    --oc-lime-9: #5c940d;
    --oc-lime-9-rgb: 92, 148, 13;
  
  
  /*  Yellow
   *  ─────────────────────────────────── */
  
    --oc-yellow-0: #fff9db;
    --oc-yellow-0-rgb: 255, 249, 219;
    --oc-yellow-1: #fff3bf;
    --oc-yellow-1-rgb: 255, 243, 191;
    --oc-yellow-2: #ffec99;
    --oc-yellow-2-rgb: 255, 236, 153;
    --oc-yellow-3: #ffe066;
    --oc-yellow-3-rgb: 255, 224, 102;
    --oc-yellow-4: #ffd43b;
    --oc-yellow-4-rgb: 255, 212, 59;
    --oc-yellow-5: #fcc419;
    --oc-yellow-5-rgb: 252, 196, 25;
    --oc-yellow-6: #fab005;
    --oc-yellow-6-rgb: 250, 176, 5;
    --oc-yellow-7: #f59f00;
    --oc-yellow-7-rgb: 245, 159, 0;
    --oc-yellow-8: #f08c00;
    --oc-yellow-8-rgb: 240, 140, 0;
    --oc-yellow-9: #e67700;
    --oc-yellow-9-rgb: 230, 119, 0;
  
  
  /*  Orange
   *  ─────────────────────────────────── */
  
    --oc-orange-0: #fff4e6;
    --oc-orange-0-rgb: 255, 244, 230;
    --oc-orange-1: #ffe8cc;
    --oc-orange-1-rgb: 255, 232, 204;
    --oc-orange-2: #ffd8a8;
    --oc-orange-2-rgb: 255, 216, 168;
    --oc-orange-3: #ffc078;
    --oc-orange-3-rgb: 255, 192, 120;
    --oc-orange-4: #ffa94d;
    --oc-orange-4-rgb: 255, 169, 77;
    --oc-orange-5: #ff922b;
    --oc-orange-5-rgb: 255, 146, 43;
    --oc-orange-6: #fd7e14;
    --oc-orange-6-rgb: 253, 126, 20;
    --oc-orange-7: #f76707;
    --oc-orange-7-rgb: 247, 103, 7;
    --oc-orange-8: #e8590c;
    --oc-orange-8-rgb: 232, 89, 12;
    --oc-orange-9: #d9480f;
    --oc-orange-9-rgb: 217, 72, 15;
  
  }

/* Neutral */

body[data-theme="dark"] {
  --neutral-0:  HSL(240, 5%, 96%);
  --neutral-1:  HSL(240, 5%, 91%);
  --neutral-2:  HSL(240, 5%, 82%);
  --neutral-3:  HSL(240, 5%, 72%);
  --neutral-4:  HSL(240, 5%, 62%);
  --neutral-5:  HSL(240, 5%, 54%);
  --neutral-6:  HSL(240, 5%, 44%);
  --neutral-7:  HSL(240, 5%, 34%);
  --neutral-8:  HSL(240, 5%, 26%);
  --neutral-9:  HSL(240, 5%, 12%);
  --neutral-10: HSL(240, 10%, 10%);
  --neutral-11: HSL(240, 10%, 8%);
  --neutral-12: HSL(240, 15%, 5%);
}

/* Primary */

body[data-theme="dark"] {
  --primary-hue: 234;
  --primary-0: hsl(var(--primary-hue), 100%, 96%);
  --primary-1: hsl(var(--primary-hue), 94%, 93%);
  --primary-2: hsl(var(--primary-hue), 94%, 86%);
  --primary-3: hsl(var(--primary-hue), 95%, 78%);
  --primary-4: hsl(var(--primary-hue), 92%, 72%); 
  --primary-5: hsl(var(--primary-hue), 91%, 67%);
  --primary-6: hsl(var(--primary-hue), 86%, 63%);
  --primary-7: hsl(var(--primary-hue), 78%, 59%);
  --primary-8: hsl(var(--primary-hue), 66%, 55%);
  --primary-9: hsl(var(--primary-hue), 56%, 49%);
}

/* Danger */

body[data-theme="dark"] {
  --danger-hue: 350;
  --danger-0: hsl(var(--danger-hue), 100%, 96%);
  --danger-1: hsl(var(--danger-hue), 94%, 93%);
  --danger-2: hsl(var(--danger-hue), 94%, 86%);
  --danger-3: hsl(var(--danger-hue), 95%, 78%);
  --danger-4: hsl(var(--danger-hue), 92%, 72%);
  --danger-5: hsl(var(--danger-hue), 91%, 67%);
  --danger-6: hsl(var(--danger-hue), 86%, 63%);
  --danger-7: hsl(var(--danger-hue), 78%, 59%);
  --danger-8: hsl(var(--danger-hue), 75%, 55%);
  --danger-9: hsl(var(--danger-hue), 75%, 49%);
  --danger-shadow: 0 0 0 3px HSL(var(--danger-hue), 92%, 20%);
}

/* Warning */

body[data-theme="dark"] {
  --warning-hue: 34;
  --warning-0: hsl(var(--warning-hue), 100%, 96%);
  --warning-1: hsl(var(--warning-hue), 94%, 93%);
  --warning-2: hsl(var(--warning-hue), 94%, 86%);
  --warning-3: hsl(var(--warning-hue), 95%, 78%);
  --warning-4: hsl(var(--warning-hue), 92%, 72%);
  --warning-5: hsl(var(--warning-hue), 91%, 67%);
  --warning-6: hsl(var(--warning-hue), 93%, 63%);
  --warning-7: hsl(var(--warning-hue), 93%, 59%);
  --warning-8: hsl(var(--warning-hue), 93%, 55%);
  --warning-9: hsl(var(--warning-hue), 93%, 49%);
}

/* Success */

body[data-theme="dark"] {
  --success-hue: 205;
  --success-0: hsl(var(--success-hue), 100%, 96%);
  --success-1: hsl(var(--success-hue), 94%, 93%);
  --success-2: hsl(var(--success-hue), 94%, 86%);
  --success-3: hsl(var(--success-hue), 95%, 78%);
  --success-4: hsl(var(--success-hue), 92%, 72%);
  --success-5: hsl(var(--success-hue), 91%, 67%);
  --success-6: hsl(var(--success-hue), 90%, 63%);
  --success-7: hsl(var(--success-hue), 85%, 59%);
  --success-8: hsl(var(--success-hue), 90%, 55%);
  --success-9: hsl(var(--success-hue), 97%, 49%);
}

/* Primaries */

body[data-theme="dark"] {
  --background: var(--neutral-12);
  --background-highlight: var(--neutral-11);
  --foreground: var(--neutral-4);
  --foreground-highlight: var(--neutral-2);
}

/* Borders */

body[data-theme="dark"] {
  --border: var(--neutral-10);
}

/* Text */

body[data-theme="dark"] {
  --text-title: var(--neutral-3);
  --text-muted: var(--neutral-6);
  --text-placeholder: var(--neutral-6);
}

/* Inputs */

body[data-theme="dark"] {
  --input-border: var(--neutral-9);
  --input-disabled-background: var(--neutral-11); 
  --input-disabled-foreground: var(--neutral-7);
  --input-focus-shadow: rgba(99, 114, 249, 0.14) 0px 0px 2px inset
    , var(--neutral-10) 0px 0px 0px 3px;
}

/* Special Cases */

body[data-theme="dark"] {
  --purple-shadow: rgba(99, 114, 249, 0.4) 0px 0px 0px 3px;
}

/* Scrollbars */

body[data-theme="dark"] {
  --scrollbar-fg: var(--neutral-9);
  --scrollbar-bg: var(--neutral-11);
}

/* Search */

body[data-theme="dark"] {
  --search-cancel-button-bg: var(--neutral-8)
}

body[data-theme="dark"] {
  --shadow-color: hsl(0, 0%, 6%);
 
  --shadow-1: 0 0 0 1px var(--shadow-color), 0 2px 4px -2px var(--shadow-color);
  --shadow-2: 0 0 0 1px var(--shadow-color), 0 4px 8px -4px var(--shadow-color);
  --shadow-3: 0 0 0 1px var(--shadow-color), 0 8px 16px -8px var(--shadow-color);
  --shadow-4: 0 0 0 1px var(--shadow-color), 0 16px 32px -16px var(--shadow-color);
     
  --shadow-top: var(--shadow-color) 0px -5px 5px -5px;
  --shadow-right: var(--shadow-color) 5px 0 5px -5px;
  --shadow-bottom: var(--shadow-color) 0px 5px 5px -5px;
  --shadow-left: var(--shadow-color) -5px 0 5px -5px;
 
  --shadow-inset: inset 0 3px 8px -5px var(--shadow-color);
}

body[data-theme="dark"] .bg-1 { background: var(--neutral-11) }

body[data-theme="dark"] .bg-2 { background: var(--neutral-10) }

body[data-theme="dark"] .bg-3 { background: var(--neutral-9) }

body[data-theme="dark"] .bg-4 { background: var(--neutral-8) }

body[data-theme="dark"] .hover-bg-1:hover { background: var(--neutral-11) }

body[data-theme="dark"] .hover-bg-2:hover { background: var(--neutral-10) }

body[data-theme="dark"] .hover-bg-3:hover { background: var(--neutral-9) }

body[data-theme="dark"] .hover-bg-4:hover { background: var(--neutral-8) }

body[data-theme="dark"] .Alert {
  --border: 1px solid var(--neutral-9);
  background: var(--neutral-11);
  border-top: var(--border);
  border-right: var(--border);
  border-bottom: var(--border);
  color: var(--foreground);
}

body[data-theme="dark"] .Button {
  color: rgba(255, 255, 255, 0.6);
}

body[data-theme="dark"] .Button:focus {
  color: rgba(255, 255, 255, 0.8);
}

body[data-theme="dark"] .Button:hover {
  color: white;
}

/* Default */

body[data-theme="dark"] .Button.default {
  border: 1px solid var(--input-border);
  background: var(--background);
}

body[data-theme="dark"] .Button.default.hover,
body[data-theme="dark"] .Button.default:hover {
  background: var(--neutral-9);
}

body[data-theme="dark"] .Button.default.active,
body[data-theme="dark"] .Button.default:active {
  background: var(--neutral-10);
}

body[data-theme="dark"] .Button.default.focus,
body[data-theme="dark"] .Button.default:focus {
  border-color: transparent;
  box-shadow: var(--input-focus-shadow);
}

body[data-theme="dark"] .Button.default.disabled {
  border: 1px solid var(--input-disabled-background);
  background: var(--input-disabled-background);
  color: var(--input-disabled-foreground);
}

/* Primary */

body[data-theme="dark"] .Button.primary {
  border: 1px solid transparent;
  background: var(--primary-6);
  color: white
}

body[data-theme="dark"] .Button.primary.hover,
body[data-theme="dark"] .Button.primary:hover {
  background: var(--primary-5);
}

body[data-theme="dark"] .Button.primary.active,
body[data-theme="dark"] .Button.primary:active {
  background: var(--primary-7);
}

body[data-theme="dark"] .Button.primary.focus,
body[data-theme="dark"] .Button.primary:focus {
  box-shadow: var(--purple-shadow);
}

body[data-theme="dark"] .Button.primary.disabled {
  color: rgba(255,255,255,.5);
}

body[data-theme="dark"] .Button.primary.pending::before {
  --color: rgba(255, 255, 255, 0.75);
}

/* Danger */

body[data-theme="dark"] .Button.danger {
  border: 1px solid transparent;
  background: var(--danger-8);
  color: white
}

body[data-theme="dark"] .Button.danger.hover,
body[data-theme="dark"] .Button.danger:hover {
  background: var(--danger-7);
}

body[data-theme="dark"] .Button.danger.active,
body[data-theme="dark"] .Button.danger:active {
  background: var(--danger-9);
}

body[data-theme="dark"] .Button.danger.focus,
body[data-theme="dark"] .Button.danger:focus {
  box-shadow: var(--danger-shadow);
}

body[data-theme="dark"] .Button.danger.disabled {
  color: rgba(255,255,255,.5);
}

body[data-theme="dark"] .Button.danger.pending::before {
  --color: rgba(255, 255, 255, 0.75);
}

/* Minimal */

body[data-theme="dark"] .Button.minimal {
  background: transparent;
}

body[data-theme="dark"] .Button.minimal.hover,
body[data-theme="dark"] .Button.minimal:hover {
  background: var(--neutral-9);
}

body[data-theme="dark"] .Button.minimal.active,
body[data-theme="dark"] .Button.minimal:active {
  background: var(--neutral-10);
}

body[data-theme="dark"] .Button.minimal.focus,
body[data-theme="dark"] .Button.minimal:focus {
  border-color: transparent;
  box-shadow: var(--input-focus-shadow);
}

body[data-theme="dark"] .Button.minimal.disabled {
  border: 1px solid var(--input-disabled-background);
  background: var(--input-disabled-background);
  color: var(--input-disabled-foreground);
}

/* Size Small */

body[data-theme="dark"] .Button.small {
  --height: 24px;
}

body[data-theme="dark"] .Button.small.pending::before {
  --size: 10px;
}

body[data-theme="dark"] .Button.small > .icon {
  width: 12px;
  height: 12px;
}

body[data-theme="dark"] .IconInput > .icon > svg {
  transition: color 200ms var(--ease);
}

body[data-theme="dark"] .IconInput:hover > .icon > svg {
  color: var(--neutral-4);
  width: 12px;
}

/* MenuItem */

body[data-theme="dark"] .MenuItem {
  color: var(--neutral-6);
}

body[data-theme="dark"] .MenuItem.selected {
  color: var(--foreground-highlight)
}

body[data-theme="dark"] .MenuItem:hover {
  background: var(--background-highlight); /* TODO: light as well */
  color: var(--neutral-0);
}

body[data-theme="dark"] .MenuItem:hover > .icon {
  stroke: var(--neutral-0);
}

body[data-theme="dark"] .MenuItem:active {
  background: var(--neutral-12);
}

/* MenuGroup */

body[data-theme="dark"] .MenuGroup > .title {
  color: var(--neutral-2)
}

body[data-theme="dark"] .MenuGroup.options > .MenuItem.selected {
  color: var(--foreground-highlight);
}

body[data-theme="dark"] .ProgressBar {
  --background: var(--neutral-10)
}

body[data-theme="dark"] .Radio {
  --background: var(--neutral-9)
}

body[data-theme="dark"] .TagInput.focus {
  box-shadow: var(--input-focus-shadow);
}

body[data-theme="dark"] .Tag {
  background: var(--neutral-9);
}

body[data-theme="dark"] .TagInput:not(.disabled) .Tag:hover {
  background: var(--neutral-9);
  color: var(--neutral-2);
}

body[data-theme="dark"] .Toggle {
  --background: var(--neutral-9)
}