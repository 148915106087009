
.QueryInput input {
  --height: 2rem;
  box-shadow: none !important;
  transition: transform 250ms var(--ease-snap);
  background: transparent;
}

.QueryInput .icon {
  transition: opacity 250ms;
  z-index: 15;
}

.QueryInput:not(.empty) .icon,
.QueryInput.focus .icon {
  opacity: 0;
} 

.QueryInput:not(.empty) input,
.QueryInput.focus input {
  transform: translateX(-1.5rem);
} 

