
.Timeseries {
  position: relative;
}

.Timeseries .stats {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 10px;
  z-index: 50;
  background: rgba(var(--oc-gray-0-rgb), 0.85);
  padding: 0.5rem;
  pointer-events: none;
  user-select: none;
}

.Timeseries .bar {
  fill: var(--primary-6) !important;
}

/* Dark theme */

body[data-theme="dark"] .Timeseries .stats {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 10px;
  z-index: 50;
  background: HSLA(240, 15%, 5%, 0.90);
  padding: 0.5rem;
  pointer-events: none;
  user-select: none;
}

body[data-theme="dark"] .Timeseries .bar {
  fill: var(--primary-6) !important;
}