.TeamSelect {
  --height: 3.5rem;
  --padding: 1.25rem;
  --icon: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNCIgaGVpZ2h0PSIxNCIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIiBzdHJva2U9IkhTTCgwLCAwJSwgNTAlKSIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiPjxwYXRoIGQ9Ik02IDlsNiA2IDYtNiIvPjwvc3ZnPgo=");
  width: 100%;
  height: var(--height);
  line-height: var(--height);
  border: none;
  border-bottom: 1px solid var(--border);
  border-radius: 0;
  padding: 0 var(--padding);
  font-weight: 600;
  font-size: 1rem;
  outline: none;
  background: var(--background) var(--icon) no-repeat;
  background-position: right var(--padding) top 50%;
  cursor: pointer;
  user-select: none;
  text-indent: -99rem;
}

/* Responsive */

.Sidebar.expanded .TeamSelect {
  text-indent: 0;
  min-width: 224px;
  animation: 350ms nav-text-show ease-out forwards;
}