
.SideSheet .Stat {
  position: relative;
}

.SideSheet .Stat .percent {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: rgba(0,0,0,0.03);
  pointer-events: none;
} 