
.Fields .list {
  scroll-snap-type: y mandatory;
}

.Fields .list::-webkit-scrollbar {
  width: 0;
}

.Field {
  --height: 1.5rem;
  display: grid;
  grid-template-columns: auto 50px 24px;
  gap: 0.5rem;
  padding: 0 0.5rem;
  height: var(--height);
  line-height: var(--height);
  border-radius: 2px;
  cursor: pointer;
  scroll-snap-align: end;
}

.Field:hover {
  /* TODO: variable? */
  background: var(--neutral-0);
}

.Field > .type {
  text-align: right;
  font-size: 0.75rem;
}

.Field > .percent {
  text-align: left;
  font-size: 0.75rem;
}

body[data-theme="dark"] .Field:hover {
  background: var(--neutral-11);
  color: var(--neutral-0);
}

body[data-theme="dark"] .Field:hover .title-1 {
  color: var(--neutral-0);
}
