
/* TODO: move to Box */

.large-field-value {
  font-size: 0.875rem;
  line-height: 1.5;
  overflow-x: hidden;
  white-space: pre-wrap;
  padding: 0.5rem 0;
  color: var(--neutral-6);
}