
.Events {
  --monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  height: 100%;
  padding: 0.25rem 0.25rem;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}

/* Event */

.Event {
  --margin: 0.5rem;
  --height: 24px;
  cursor: pointer;
  font-size: 12px;
  border-radius: 2px;
  border-bottom: 1px solid #ededed;
}

.Event:hover {
  background: var(--neutral-1);
} 

.Event.highlight { 
  background: var(--neutral-2);
}

.Event:last-child {
  border-bottom: none;
}

/* Menu */

.Event .Button { 
  margin-right: 0.25rem;
}

/* Level */

.Event .level {
  line-height: var(--height);
  text-align: right;
  flex-shrink: 0;
  margin-right: var(--margin);
  font-weight: bold;
  text-transform: uppercase;
  color: var(--color);
  font-family: var(--monospace);
  font-size: 11px;
}

/* Timestamp */

.Event .timestamp {
  line-height: var(--height);
  color: var(--neutral-6); 
  margin-right: var(--margin);
  white-space: nowrap;
  font-size: 11px;
  letter-spacing: -0.02rem;
  font-family: var(--monospace);
}

/* Message */

.Event .message {
  line-height: var(--height);
  margin-right: var(--margin);
  white-space: nowrap;
  color: var(--neutral-7);
}

/* Fields */

.Event .field {
  line-height: var(--height);
  margin-right: var(--margin);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Event .field > .name {
  font-weight: 500;
  border-radius: 2px;
  margin-right: 2px;
  padding-left: 3px;
  background: var(--background);
  color: var(--color);
}

.Event .field > .name::after {
  content: ':';
  margin-right: 2px;
}

.Event .field > .name:hover {
  background: var(--background-hover);
}

.Event .field > .value {
  color: var(--neutral-7);
}

/* Truncate mode */

.Events.truncate .Event {
  display: flex;
  overflow-x: hidden;
} 
 
.Events.truncate .Event .fields {
  display: inline-flex;
} 

.Events.truncate .Event .details {
  display: contents;
}

/* Wrapped mode */

.Events.wrapped .Event {
  display: grid;
  grid-template-columns: repeat(3, min-content) auto;
} 

.Events.wrapped .Event .message {
  white-space: pre-wrap;
}

.Events.wrapped .Event .details {
  display: inline-flex;
  flex-wrap: wrap;
}

.Events.wrapped .Event .fields {
  display: contents;
}

.Events.wrapped .Event .fields > .field {
  display: inline-block;
} 

.Events.wrapped .Event .fields > .field > .value {
  white-space: nowrap;
} 

/* Expand mode */

.Events.expand .Event {
  display: grid;
  grid-template-columns: repeat(3, min-content) 1fr;
}

.Events.expand .Event .details {
  display: contents;
}

.Events.expand .Event .fields {
  padding: 0.5rem 0;
  line-height: 1.6;
  grid-column: 2 / -1;
} 

.Events.expand .Event .field {
  display: block;
}

.Event.debug {
  --color: var(--neutral-7);
  --background: var(--neutral-1);
  --background-hover: var(--neutral-3);
}

.Event.info {
  --color: var(--oc-indigo-9);
  --background: var(--oc-indigo-0);
  --background-hover: var(--oc-indigo-1);
}

.Event.notice {
  --color: var(--oc-yellow-9);
  --background: var(--oc-yellow-0);
  --background-hover: var(--oc-yellow-1);
}

.Event.warning {
  --color: var(--oc-orange-9);
  --background: var(--oc-orange-0);
  --background-hover: var(--oc-orange-1);
}

.Event.error {
  --color: var(--oc-red-9);
  --background: var(--oc-red-0);
  --background-hover: var(--oc-red-1);
}

.Event.critical {
  --color: var(--oc-pink-9);
  --background: var(--oc-pink-0);
  --background-hover: var(--oc-pink-1);
}

.Event.alert {
  --color: var(--oc-pink-9);
  --background: var(--oc-pink-0);
  --background-hover: var(--oc-pink-1);
}

.Event.emergency {
  --color: var(--oc-pink-9);
  --background: var(--oc-pink-0);
  --background-hover: var(--oc-pink-1);
}

/* Light theme */

/* Button colors */

body[data-theme="light"] .Event .Button:hover {
  background: var(--neutral-2);
}

body[data-theme="light"] .Event .Button:active {
  background: var(--neutral-3);
}

/* Dark theme */

body[data-theme="dark"] .Event {
  border-bottom: 1px solid var(--border);
}

body[data-theme="dark"] .Event:hover {
  background: var(--neutral-10);
}

body[data-theme="dark"] .Event.highlight {
  background: var(--neutral-9);
}

/* Message */

body[data-theme="dark"] .Event .message {
  color: var(--neutral-4);
}

/* Fields */

body[data-theme="dark"] .Event .field > .name {
  background: transparent;
  color: var(--color);
  color: var(--neutral-7);
  font-weight: 600;
  padding-left: 0; 
}

body[data-theme="dark"] .Event .field > .name::after {
  margin-right: 1px;
}

body[data-theme="dark"] .Event .field > .name:hover {
  background: var(--neutral-9);
  color: var(--neutral-4);
}

body[data-theme="dark"] .Event .field > .value {
  color: var(--neutral-5);
}

body[data-theme="dark"] .Event.debug {
  --color: var(--neutral-7);
}

body[data-theme="dark"] .Event.info {
  --color: var(--primary-6);
}

body[data-theme="dark"] .Event.notice {
  --color: var(--oc-yellow-9);
}

body[data-theme="dark"] .Event.warning {
  --color: var(--oc-orange-9);
}

body[data-theme="dark"] .Event.error {
  --color: var(--oc-red-9);
}

body[data-theme="dark"] .Event.critical {
  --color: var(--oc-pink-9);
}

body[data-theme="dark"] .Event.alert {
  --color: var(--oc-pink-9);
}

body[data-theme="dark"] .Event.emergency {
  --color: var(--oc-pink-9);
}

/* Button colors */

body[data-theme="dark"] .Event .Button:hover {
  background: var(--neutral-9);
}

body[data-theme="dark"] .Event .Button:active {
  background: var(--neutral-10);
}


