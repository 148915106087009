
/* TODO: better solution for this */

body[data-theme="light"] .QueryHistory .item .Button:hover {
  background: var(--neutral-2);
}

body[data-theme="light"] .QueryHistory .item .Button:active {
  background: var(--neutral-3);
}

/* Dark theme */

body[data-theme="dark"] .QueryHistory .item .Button:hover {
  background: var(--neutral-9);
}

body[data-theme="dark"] .QueryHistory .item .Button:active {
  background: var(--neutral-10);
}