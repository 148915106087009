
.JSON {
  --color: var(--primary-9);
  --background: var(--primary-0);
  --background-hover: var(--primary-1);
  --string: var(--oc-cyan-6);
  --number: var(--oc-pink-7);
  --boolean: var(--oc-blue-7);
  font-family: Menlo, Monaco, 'Courier New', monospace;
  line-height: 1.5;
  overflow-x: auto;
  color: var(--color);
}

.JSON.wrap {
  white-space: pre-wrap;
  word-break: break-all;
}

.JSON .key {
  user-select: all;
  font-weight: 500;
  border-radius: 2px;
  margin-right: 4px;
  padding: 0 2px 0 4px;
  background: var(--background);
  color: var(--color);
}

.JSON .key:hover {
  background: var(--background-hover);
}

.JSON .key::after {
  content: ':';
} 

.JSON .string {
  color: var(--string);
  user-select: all;
}

.JSON .string::before {
  display: inline-block;
  content: '"';
}

.JSON .string::after {
  display: inline-block;
  content: '"';
}

.JSON .number {
  color: var(--number);
  user-select: all;
}

.JSON .boolean {
  color: var(--boolean);
  user-select: all;
}

body[data-theme="dark"] .JSON {
  --color: var(--primary-6);
  --background: var(--neutral-10);
  --background-hover: var(--neutral-9);
}