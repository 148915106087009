
.BarChart .column {
  fill: transparent;
  z-index: 25;
}

.BarChart .overlay:hover {
  fill: rgba(var(--oc-indigo-1-rgb), 0.5) !important;
}

.BarChart .axis text {
  font: 9px sans-serif;
}

.BarChart .axis path,
.BarChart .axis line {
  fill: none;
  stroke: RGB(230, 237, 244);
  stroke-width: 1.5px;
  shape-rendering: crispEdges;
}

.BarChart .axis path {
  display: none;
}
